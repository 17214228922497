// We want to split public (embed player) API from the main one to help
// parcel tree-shake code more effectively

import to from 'await-to-js';
import axios from 'axios';
import { makeUrl } from '@voomly/utils';
import { IFunnelsRaw } from '../../types/funnels';

export const fetchPublicInfoFunnel = async (funnelId: string) => {
  const [err, response] = await to(
    axios.get<IFunnelsRaw>(makeUrl(`/video-funnels/${funnelId}`))
  );

  if (response) {
    return response.data;
  } else {
    throw err;
  }
};
