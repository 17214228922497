import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import tinycolor from '@ctrl/tinycolor';
import { DetachedStoreContext } from '../../../contexts/DetachedStoreContext';
import { IPlayerTemplate } from '../../../types/player';
import { ITimelineItem } from '../../../types';
import { requestPause, requestPlay } from '../../../store/videoState/actions';
import { getItemsOnScreen } from '../../../store/videoItems/selectors';
import {
  getHasStarted,
  getIsPlayRequested,
  getIsVideoFinished,
  getIsVideoPaused,
} from '../../../store/videoState/selectors';
import { getIsThumbnailVisible } from '../../../store/thumbnail/selectors';
import { visibleAskToResumeOverlaySelector } from '../../../store/askToResumeOnVisitOverlay/selectors';
import { selectContainerButtonsScale } from '../../../store/containerDimensions/selectors';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { Loader } from '../../Loader';
import { shouldShowReWatchButton } from '../RewatchButton/selector';
import { getStyles } from '../skins/getStyles';
import { usePlayerSkin } from '../skins/PlayerSkinContext';
import styles from './playButton.module.sass';

const ScalableDiv: React.FC<{
  playButton: IPlayerTemplate['controls']['playButton'];
  buttonsScale: number;
}> = ({ playButton, buttonsScale, children }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        transform: `scale(${
          playButton === 'large' ? buttonsScale * 1.4 : buttonsScale
        })`,
      }}
    >
      {children}
    </div>
  );
};

export const PlayButtonOverlay = ({
  player,
  allControlsAreAlwaysVisible,
}: IDefaultPropTypes) => {
  const dispatch = useDispatch();
  const detachedStore = useContext(DetachedStoreContext);
  const [isOverPlayButton, setIsOverlayPlayButton] = useState(false);
  const videoItemsOnScreen = useSelector(getItemsOnScreen);
  const visibleThumbnail = useSelector(getIsThumbnailVisible);
  const paused = useSelector(getIsVideoPaused);
  const hasStarted = useSelector(getHasStarted);
  const isPlayRequested = useSelector(getIsPlayRequested);
  const isVideoEnded = useSelector(getIsVideoFinished);
  const isAskToResumeVisible = useSelector(visibleAskToResumeOverlaySelector);
  const buttonsScale = useSelector(selectContainerButtonsScale);
  const skin = usePlayerSkin();

  const {
    controls: { playButton, customPlayButtonUrl },
  } = player;
  const PlayIcon = skin.icons.play;

  const getSelectedItemsOnScreen = useCallback((): ITimelineItem[] => {
    if (detachedStore) {
      const selectedItemIds = detachedStore.selectedItemIds;
      return videoItemsOnScreen.filter(({ id }) =>
        selectedItemIds.includes(id)
      );
    } else {
      return [];
    }
  }, [detachedStore, videoItemsOnScreen]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const selectedItemsOnScreen = getSelectedItemsOnScreen();

      // It will not play if there is some selected items on screen
      // It will deselect them first. It gives better UX
      if (detachedStore && selectedItemsOnScreen.length > 0) {
        detachedStore.handleMultiDeselect();

        return;
      }

      if (paused) {
        dispatch(requestPlay({}));
      } else {
        dispatch(requestPause({}));
      }
    },
    [detachedStore, dispatch, getSelectedItemsOnScreen, paused]
  );

  const isLarge = playButton === 'large';
  const scale = isLarge ? buttonsScale * 1.4 : buttonsScale;
  const playButtonColor =
    getStyles('playButton__icon', skin, player).color || '#fff';

  const showLoader =
    !allControlsAreAlwaysVisible &&
    !hasStarted &&
    (isPlayRequested || player.controls.autoplay) &&
    visibleThumbnail;

  const [playButtonWidth, setPlayButtonWidth] = useState<number | undefined>(
    undefined
  );

  const handleSetPlayButtonWidth = useCallback(
    (node: HTMLSpanElement) => {
      if (!node || showLoader || playButton === 'custom') {
        setPlayButtonWidth(undefined);
        return;
      }

      const playButtonIconWrapper = node;

      if (playButtonIconWrapper) {
        const iconSvg = playButtonIconWrapper.querySelector('svg');
        if (!iconSvg) return;
        setPlayButtonWidth(parseFloat(iconSvg.width.baseVal.valueAsString));
      } else {
        setPlayButtonWidth(undefined);
      }
    },
    [playButton, showLoader]
  );

  if (!allControlsAreAlwaysVisible) {
    // if showing rewatch, then we should not stack buttons on top
    // of each other
    if (shouldShowReWatchButton(player, isVideoEnded)) {
      return null;
    }

    // do not show play button is video starts with the funnel
    if (isAskToResumeVisible) return null;
  }

  return (
    <button
      tabIndex={0}
      type="button"
      aria-live="polite"
      onClick={handleClick}
      className={
        showLoader
          ? cx(styles.root, {
              [styles.visible]: true,
            })
          : cx(styles.root, skin.classes.playButton__root, {
              [styles.visible]:
                visibleThumbnail ||
                (allControlsAreAlwaysVisible &&
                  !(isPlayRequested || player.controls.autoplay)),
              [skin.classes.playButton__visible]:
                visibleThumbnail ||
                (allControlsAreAlwaysVisible &&
                  !(isPlayRequested || player.controls.autoplay)),
            })
      }
    >
      {showLoader ? (
        <ScalableDiv playButton={playButton} buttonsScale={buttonsScale}>
          <Loader delay={2000} />
        </ScalableDiv>
      ) : playButton === 'custom' ? (
        customPlayButtonUrl ? (
          <img className={styles.icon} src={customPlayButtonUrl} alt="" />
        ) : undefined
      ) : (
        // PlayIcon doesn't have a 'ref' prop on the embed player,
        // but it has 'ref' on all other players
        // Use iconWrapper here to get svg data
        <span ref={handleSetPlayButtonWidth}>
          <PlayIcon
            onMouseEnter={() => setIsOverlayPlayButton(true)}
            onMouseLeave={() => setIsOverlayPlayButton(false)}
            className={cx(styles.icon, skin.classes.playButton__icon)}
            style={{
              color: isOverPlayButton
                ? tinycolor(playButtonColor).desaturate(25).toHexString()
                : playButtonColor,
              width: playButtonWidth
                ? (playButtonWidth * scale).toFixed() + 'px' // FF will not work without px
                : undefined,
              height: 'auto', // Allow browser adjust the height, in this case, we will not have blurry SVG more often
            }}
          />
        </span>
      )}
    </button>
  );
};
