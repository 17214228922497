import type { IPlayerTemplate, IPlayerValues } from '../types/player';

export const DEFAULT_PLAYER_DATA: IPlayerValues = {
  name: 'Voomly Player',
  appearance: {
    bgColor: '#008EFF',
    bgColorEnabled: true,
    color: '#FFFFFF',
    logoEnabled: true,
    useCustomLogo: false,
    customLogoUrl: undefined,
    customUrl: undefined,
    controlBarSkin: 'default',
    borderSkinEnabled: false,
    borderSkin: 'iphone',
    uiOpacity: 85,
  },
  controls: {
    autoplay: false,
    resumeOnFutureVisitsEnabled: false,
    muted: false,
    playBar: true,
    showPlayBar: true,
    visibleControlsOnLoad: true,
    showVolumeButton: true,
    showSelectSpeedButton: true,
    showFullscreenButton: true,
    showPlayButton: true,
    showSelectQualityButton: true,
    showTime: true,
    playButton: 'large',
    customPlayButtonUrl: undefined,
    showRewatchButton: true,
    onEnd: {
      action: 'RESET_TO_THUMBNAIL',
      redirectUrl: undefined,
    },
    enableSoundButtonLocation: 'top-right',
  },
  passwordProtection: {
    enabled: false,
    password: undefined,
    title: 'Video is Password Protected',
    enterPasswordText: 'Please enter password to view video:',
    submitButtonText: 'Unlock',
    submitButtonLoadingText: 'Loading...',
    emptyPasswordErrorText: 'Password is a required field',
    wrongPasswordErrorText: 'Wrong password',
  },
  captions: {
    playText: 'Play',
    pauseText: 'Pause',
    playbackSpeedText: 'Playback speed',
    volumeText: 'Volume',
    qualityText: 'Quality',
    fullscreenText: 'Full screen',
    normalSpeedText: 'Normal',
    rewatchText: 'Rewatch',
    enableSoundText: 'Enable sound',
    logoText: 'Logo',
  },
};

export const emptyPlayerConfig: IPlayerTemplate = {
  name: '@@SPECIAL_EMPTY_PLAYER@@',

  passwordProtection: {
    enabled: false,
    title: 'Video is Password Protected',
    password: undefined,
    enterPasswordText: 'Please enter password to view video:',
    submitButtonText: 'Unlock',
    submitButtonLoadingText: 'Loading...',
    emptyPasswordErrorText: 'Password is a required field',
    wrongPasswordErrorText: 'Wrong password',
  },
  appearance: {
    bgColor: '#008EFF',
    bgColorEnabled: true,
    color: '#FFFFFF',
    logoEnabled: true,
    useCustomLogo: false,
    customLogoUrl: undefined,
    customUrl: undefined,
    controlBarSkin: 'pult',
    borderSkinEnabled: false,
    borderSkin: 'iphone',
    uiOpacity: 85,
  },
  controls: {
    autoplay: false,
    resumeOnFutureVisitsEnabled: true,
    muted: false,
    playBar: true,
    showPlayBar: true,
    visibleControlsOnLoad: true,
    showVolumeButton: true,
    showSelectSpeedButton: true,
    showFullscreenButton: true,
    showPlayButton: true,
    showSelectQualityButton: true,
    showTime: true,
    playButton: 'large',
    customPlayButtonUrl: undefined,
    showRewatchButton: true,
    onEnd: {
      action: 'RESET_TO_THUMBNAIL',
      redirectUrl: undefined,
    },
    enableSoundButtonLocation: 'top-right',
  },

  captions: DEFAULT_PLAYER_DATA.captions,

  createdAt: '2020-02-28T16:33:28.760Z',
  updatedAt: '2020-04-14T23:18:28.612Z',
  deletedAt: '2020-04-14T23:18:28.612Z',
};
