import { ActionType, getType } from 'typesafe-actions';
import * as videoStateActions from './../videoState/actions';
import * as actions from './actions';

export interface IVideoControlsState {
  areControlsVisible: boolean;
  controlsStayVisibleBy: Array<'SELECT_SPEED' | 'SELECT_QUALITY'>;
  areSeekControlsBlocked: boolean;
  isLogoVisible: boolean;
  isClickForSoundVisible: boolean;
}

const initialState: IVideoControlsState = {
  areControlsVisible: false,
  controlsStayVisibleBy: [],
  areSeekControlsBlocked: false,
  isLogoVisible: false,
  isClickForSoundVisible: false,
};

type IAction = ActionType<typeof videoStateActions | typeof actions>;

export const videoControlsReducer = (
  state: IVideoControlsState = initialState,
  action: IAction
): IVideoControlsState => {
  switch (action.type) {
    case getType(actions.addControlsStayVisibleBy):
      return {
        ...state,
        controlsStayVisibleBy: state.controlsStayVisibleBy.includes(
          action.payload
        )
          ? state.controlsStayVisibleBy
          : [...state.controlsStayVisibleBy, action.payload],
      };

    case getType(actions.removeControlsStayVisibleBy):
      return {
        ...state,
        controlsStayVisibleBy: state.controlsStayVisibleBy.filter(
          (p) => p !== action.payload
        ),
      };

    case getType(actions.showControls):
      return { ...state, areControlsVisible: true };

    case getType(actions.hideControls):
      return { ...state, areControlsVisible: false };

    case getType(actions.changeClickForSoundVisibility):
      return { ...state, isClickForSoundVisible: action.payload.isVisible };

    case getType(videoStateActions.requestVolumeChange):
      if (
        'mute' in action.payload &&
        action.payload.mute &&
        action.payload.by === 'autoplay'
      ) {
        return {
          ...state,
          isClickForSoundVisible: true,
        };
      } else {
        return {
          ...state,
          isClickForSoundVisible: false,
        };
      }

    case getType(videoStateActions.volumeChanged):
      if (!action.payload.muted) {
        return {
          ...state,
          isClickForSoundVisible: false,
        };
      }

      return state;

    case getType(videoStateActions.videoPlayStarted):
      return { ...state, isLogoVisible: true };

    case getType(videoStateActions.videoPaused):
      return { ...state, areControlsVisible: true };

    default:
      return state;
  }
};
