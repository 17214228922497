import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../sourceConfiguration/actions';

export interface IFunnelState {
  muted?: boolean;
  volume?: number;
}

const initialState: IFunnelState = {
  muted: undefined,
  volume: undefined,
};

type IAction = ActionType<typeof actions>;

// Share state across the funnel and store it in one place
export const funnelStateReducer = (
  state: IFunnelState = initialState,
  action: IAction
): IFunnelState => {
  switch (action.type) {
    // Saving state right after request to navigation to overwrite values
    // when next video will autoplay
    case getType(actions.requestNavigateToOtherNode):
      return { ...state, ...action.payload };
  }

  return state;
};
