import * as React from 'react';
import styled from 'styled-components';
import { IDetachableInnerProps } from '../detachableStoreHoc';
import { UploadableImageContent } from '../shared/UploadableImageContent';
import { IVideoItemSharedProps } from '../sharedTypes';
import { ITimelineImage } from '../../../types';

export interface IImageItemProps
  extends IDetachableInnerProps<ITimelineImage>,
    IVideoItemSharedProps {
  onClick?: (item: ITimelineImage) => void;
}

interface IProps {
  item: ITimelineImage;
  buttonsScale: number;
  onClick?(item: ITimelineImage): void;
  className?: string;
  uploadProgress?: number | undefined;
}

interface IWrapperProps {
  item: ITimelineImage;
  buttonsScale: number;
}

const Wrapper = styled.div.attrs<IWrapperProps>(({ item, buttonsScale }) => ({
  style: {
    width: item.width * buttonsScale,
    height: item.height * buttonsScale,
    zIndex: item.zIndex,
  },
}))<IWrapperProps>`
  overflow: hidden;
  border: none;
  position: relative;
`;

export const ImageItem = React.forwardRef(
  (
    { item, buttonsScale, onClick, className, uploadProgress }: IProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <Wrapper
      item={item}
      buttonsScale={buttonsScale}
      ref={ref}
      className={className}
    >
      <UploadableImageContent
        item={item}
        onClick={onClick}
        uploadProgress={uploadProgress}
        style={{ opacity: (item.opacity ?? 100) / 100 }}
      />
    </Wrapper>
  )
);
