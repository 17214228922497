import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Flex } from '@voomly/ui/player-deps';

import { ReactComponent as TwitterIcon } from '../../../svg/twitter.svg';
import { ReactComponent as FacebookIcon } from '../../../svg/fb.svg';
import { ReactComponent as LinkedinIcon } from '../../../svg/linkedin.svg';
import { ReactComponent as BigDottedArrow3 } from '../../../svg/big-dotted-arrow-3.svg';

import { IDetachableInnerProps } from '../detachableStoreHoc';
import { IVideoItemSharedProps } from '../sharedTypes';
import {
  VideoItemCommonButton,
  VideoItemCommonText,
} from '../shared/commonComponents';
import styles from './share.module.sass';
import { ITimelineItem, ITimelineShareGate } from '../../../types';

const Title = styled(VideoItemCommonText)`
  display: flex;
  letter-spacing: -1px;
`;

const StyledButton = styled(VideoItemCommonButton)`
  svg {
    width: 65%;
    height: auto;
    max-height: 65%;
  }
`;

const StyledArrow = styled(({ buttonsScale, ...rest }) => (
  <BigDottedArrow3 {...rest} />
))<{ buttonsScale: number }>`
  position: absolute;
  height: auto;
  width: ${({ buttonsScale }) => `${60 * buttonsScale}px`};
  bottom: ${({ buttonsScale }) => `${30 * buttonsScale}px`};
  right: ${({ buttonsScale }) => `${30 * buttonsScale}px`};
`;

export interface IShareGateTarget {
  icon: React.ReactNode;
  text: string;
  getUrl: (url: string) => string;
}

interface ICallbacks {
  doneSharingScreen: boolean;
  onShareButtonClick: (target: IShareGateTarget) => void;
  onBackFromSharingClick: () => void;
  onContinueAfterSharingClick: () => void;
}

export interface IShareGateItemBaseProps
  extends IDetachableInnerProps<ITimelineShareGate>,
    IVideoItemSharedProps {
  onDismiss: (item: ITimelineItem, remember?: boolean) => void;
}

interface IPropsWithConfig extends IShareGateItemBaseProps {
  titleText: React.ReactNode;
  subTitleText: React.ReactNode;
}

const TARGETS: { [key: string]: IShareGateTarget } = {
  facebook: {
    icon: <FacebookIcon />,
    text: 'Facebook',
    getUrl: (url) => `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  },
  twitter: {
    icon: <TwitterIcon />,
    text: 'Twitter',
    getUrl: (url) => `https://twitter.com/share?url=${url}`,
  },
  linkedin: {
    icon: <LinkedinIcon />,
    text: 'LinkedIn',
    getUrl: (url) => `https://linkedin.com/shareArticle?mini=true&url=${url}`,
  },
};

const ShareButton = ({
  id,
  onClick,
  borderRadius,
  backgroundColor,
  color,
  buttonsScale,
}: {
  id: string;
  onClick: (target: IShareGateTarget) => void;
  borderRadius: number;
  backgroundColor: string;
  color: string;
  buttonsScale: number;
}): JSX.Element => {
  const target = TARGETS[id];
  const handleClick = useCallback(() => onClick(TARGETS[id]), [onClick, id]);

  return (
    <StyledButton
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      color={color}
      buttonsScale={buttonsScale}
      margin={`0 ${5 * buttonsScale}px`}
      width={32}
      height={32}
      onClick={handleClick}
    >
      {target.icon}
    </StyledButton>
  );
};

export const ShareGateItemBase = ({
  item,
  buttonsScale,
  onShareButtonClick,
  doneSharingScreen,
  onBackFromSharingClick,
  onContinueAfterSharingClick,
  titleText,
  subTitleText,
  onWrapperMouseDown,
}: IPropsWithConfig & ICallbacks) => {
  const design = 1; // force 1, should be: item.design

  return (
    <div className={styles.share} onMouseDown={onWrapperMouseDown}>
      {doneSharingScreen ? (
        <div
          className={styles.group}
          style={{
            backgroundColor: item.backgroundColor,
            padding: 20 * buttonsScale,
          }}
        >
          <VideoItemCommonText
            buttonsScale={buttonsScale}
            color={item.titleFontColor}
            padding="0"
            fontSize={30}
            margin={`0 0 ${20 * buttonsScale}px`}
            fontStyle={['bold']}
          >
            {item.doneSharingText}
          </VideoItemCommonText>
          <Flex container={true}>
            <StyledButton
              buttonsScale={buttonsScale}
              borderRadius={item.buttonsBorderRadius}
              backgroundColor={item.buttonsBackgroundColor}
              color={item.buttonsTextColor}
              width={100}
              height={25}
              margin={`0 ${5 * buttonsScale}px`}
              onClick={onBackFromSharingClick}
            >
              {item.backText}
            </StyledButton>
            <StyledButton
              buttonsScale={buttonsScale}
              borderRadius={item.buttonsBorderRadius}
              backgroundColor={item.buttonsBackgroundColor}
              color={item.buttonsTextColor}
              width={100}
              height={25}
              margin={`0 ${5 * buttonsScale}px`}
              onClick={onContinueAfterSharingClick}
            >
              {item.continueText}
            </StyledButton>
          </Flex>
        </div>
      ) : (
        <div
          className={styles.group}
          style={{
            backgroundColor: item.backgroundColor,
            padding: 20 * buttonsScale,
          }}
        >
          {design === 1 && <StyledArrow buttonsScale={buttonsScale} />}
          {/** design === 3 && <BigDottedArrow2 className={styles.bigArrow} /> */}
          <Flex margin={`0 0 ${15 * buttonsScale}px`} width="100%">
            <Title
              buttonsScale={buttonsScale}
              fontSize={item.titleFontSize}
              fontStyle={item.titleFontStyle}
              color={item.titleFontColor}
              padding="0"
              margin={`0 0 ${5 * buttonsScale}px`}
            >
              {titleText}
            </Title>
            <Title
              buttonsScale={buttonsScale}
              fontSize={item.subTitleFontSize}
              fontStyle={item.subTitleFontStyle}
              color={item.subTitleFontColor}
              padding="0"
            >
              {subTitleText}
            </Title>
          </Flex>

          <Flex
            container={true}
            alignItems="center"
            justifyContent="center"
            padding={`${8 * buttonsScale}px ${12 * buttonsScale}px`}
          >
            {item.shareButtonOptions.indexOf('facebook') > -1 && (
              <ShareButton
                id="facebook"
                onClick={onShareButtonClick}
                borderRadius={item.buttonsBorderRadius}
                backgroundColor={item.buttonsBackgroundColor}
                color={item.buttonsTextColor}
                buttonsScale={buttonsScale}
              />
            )}
            {item.shareButtonOptions.indexOf('twitter') > -1 && (
              <ShareButton
                id="twitter"
                onClick={onShareButtonClick}
                borderRadius={item.buttonsBorderRadius}
                backgroundColor={item.buttonsBackgroundColor}
                color={item.buttonsTextColor}
                buttonsScale={buttonsScale}
              />
            )}
            {item.shareButtonOptions.indexOf('linkedin') > -1 && (
              <ShareButton
                id="linkedin"
                onClick={onShareButtonClick}
                borderRadius={item.buttonsBorderRadius}
                backgroundColor={item.buttonsBackgroundColor}
                color={item.buttonsTextColor}
                buttonsScale={buttonsScale}
              />
            )}
          </Flex>
        </div>
      )}
    </div>
  );
};
