import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useClickAway } from 'react-use';
import PopoverTip from '../PopoverTip/PopoverTip';
import Button, { IButtonProps } from '../Button/Button';
import { getStyles } from '../skins/getStyles';
import styles from './select.module.sass';
import { IPlayerTemplate } from '../../../types/player';
import { usePlayerSkin } from '../skins/PlayerSkinContext';

export const Select = ({
  className,
  buttonProps,
  config,
  options,
  selectedValue,
  onSelect,
  onChangeOpen,
}: {
  className: string;
  buttonProps: IButtonProps;
  config: IPlayerTemplate;
  options: { value: number; label: string }[];
  selectedValue: number;
  onSelect: (value: number) => void;
  onChangeOpen?: (open: boolean) => void;
}) => {
  const selectRef = useRef(null);
  const skin = usePlayerSkin();
  const popoverRef = useRef(null);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    onChangeOpen?.(opened);
  }, [opened, onChangeOpen]);

  useClickAway(selectRef, () => {
    setOpened(false);
  });

  const handleSelect = useCallback(
    (value: number, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.stopPropagation();
      onSelect(value);

      // Delay closing, so that user sees the new selection
      setTimeout(() => setOpened(false), 120);
    },
    [onSelect]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setOpened(!opened);
    },
    [opened]
  );

  return (
    <div ref={selectRef} className={cx(styles.root, className)}>
      <div
        ref={popoverRef}
        className={cx(styles.popover, skin.classes.controls__popover, {
          [styles.withTip]: Boolean(skin.tips),
          [styles.opened]: opened,
          [skin.classes.controls__popoverOpened]:
            skin.classes.controls__popoverOpened && opened,
        })}
        style={getStyles('controls__popover', skin, config)}
      >
        <div className={cx(styles.select, skin.classes.select)}>
          {options.map(({ value, label }) => {
            const selected = value === selectedValue;
            const className = cx(
              styles.option,
              skin.classes.controls__selectOption,
              {
                [styles.selected]: selected,
                [skin.classes.controls__selectedOption]: selected,
              }
            );
            const style = {
              ...getStyles('controls__selectOption', skin, config),
              ...(selected
                ? getStyles('controls__selectedOption', skin, config)
                : {}),
            };

            return (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={value}
                className={className}
                style={style}
                onClick={(event) => handleSelect(value, event)}
              >
                <span className={styles.icon} />
                <span className={styles.label}>{label}</span>
              </a>
            );
          })}
          {skin.tips && <PopoverTip skin={skin} config={config} />}
        </div>
      </div>
      <Button {...buttonProps} onClick={handleClick} />
    </div>
  );
};
