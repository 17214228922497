import { BaseGridItem, IBaseGridItemProps } from './Base';
import { appearAnimationHoc } from '../playerHocs/appearAnimationHoc';
import * as React from 'react';
import { ITimelineItemClickDescriptor } from '../../../types';

class PlayerGridComp extends React.PureComponent<IBaseGridItemProps> {
  private handleButtonClick = (item: ITimelineItemClickDescriptor) => {
    this.props.onButtonClick(item);
  };
  private getLabelForButton = (index: number) => {
    const {
      item: { buttons },
    } = this.props;
    return buttons[index].label;
  };

  public render() {
    const { item } = this.props;
    return (
      <BaseGridItem
        {...this.props}
        titleText={item.label}
        getLabelForButton={this.getLabelForButton}
        onButtonClick={this.handleButtonClick}
      />
    );
  }
}

export const PlayerGridItem = appearAnimationHoc()(PlayerGridComp);
