import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IPlayerRootReducerShape } from '../../store/rootPlayerStore';
import {
  ITimelineItem,
  ITimelineItemClickDescriptor,
  ITimelineItemDismissDescriptor,
} from '../../types';
import { shallowArrayEqual } from '@voomly/utils';
import styled from 'styled-components';
import { PlayerMode } from '../types/defaultPropTypes';
import { Transition, TransitionGroup } from 'react-transition-group';
import { VideoItemsContainerContext } from './VideoItemsContainerContext';
import RectangleSelect from './RectangleSelect';
import { GuideLinesContainer } from './GuideLinesContainer';
import { DetachedStoreContext } from '../../contexts/DetachedStoreContext';
import { VideoComponentRenderer } from './VideoComponentRenderer';
import { getItemsOnScreen } from '../../store/videoItems/selectors';

interface IProps {
  buttonsScale: number;
  onTimelineItemClick?: (item: ITimelineItemClickDescriptor) => void;
  onTimelineItemDismiss?: (
    item: ITimelineItemDismissDescriptor,
    remember?: boolean
  ) => void;
  playerMode: PlayerMode;
  itemWrapperHtmlElsRef: React.RefObject<Record<string, HTMLElement>>;
}

type ICombinedProps = IProps & ConnectedProps<typeof hoc>;

const ContainerDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 4;

  pointer-events: none;
  > div {
    pointer-events: all;
  }
`;

// const FullScreenShiftContainer = styled.div<IFullScreenShiftContainer>`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   left: ${props => props.left + 'px'};
//   top: ${props => props.top + 'px'};

//   && {
//     pointer-events: none;
//   }
//   > div {
//     pointer-events: all;
//   }
// `;

interface IAnnotationsGroup {
  buttonsScale: number;
}
const AnnotationsGroup = styled(({ buttonsScale, ...rest }) => (
  <TransitionGroup {...rest} />
)).attrs<IAnnotationsGroup>(
  ({
    buttonsScale,
  }): {
    style: React.CSSProperties;
  } => ({
    style: {
      padding: 20 * buttonsScale,
    },
  })
)<IAnnotationsGroup>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: none !important;
  > div {
    pointer-events: all;
  }
`;

class VideoItemsContainerComp extends React.Component<ICombinedProps> {
  static contextType = DetachedStoreContext;
  context!: React.ContextType<typeof DetachedStoreContext>;

  private itemWrapperHtmlEls: Record<string, HTMLElement> = {};

  public shouldComponentUpdate(nextProps: Readonly<ICombinedProps>): boolean {
    // The purpose of this Container is to just render items if they are on screen;
    // VideoItemsManager decides if an item should be on the screen, therefore,
    // re-render ONLY if something added OR removed on screen,
    // do nothing if something is changed
    const prevItems = nextProps.allItemsOnScreen;
    const curItems = this.props.allItemsOnScreen;

    return (
      this.props.buttonsScale !== nextProps.buttonsScale ||
      this.props.isThumbnailActive !== nextProps.isThumbnailActive ||
      this.props.hasStarted !== nextProps.hasStarted ||
      !shallowArrayEqual(curItems, prevItems)
    );
  }

  private handleButtonClick = (item: ITimelineItemClickDescriptor) => {
    const { onTimelineItemClick } = this.props;
    onTimelineItemClick?.(item);
  };

  private handleDismiss = (
    item: ITimelineItemDismissDescriptor,
    remember?: boolean
  ) => {
    const { onTimelineItemDismiss } = this.props;
    onTimelineItemDismiss?.(item, remember);
  };

  private handleWrapperMouseDown =
    (id: string) => (event: React.MouseEvent) => {
      if (!this.context) {
        return;
      }

      const isModifierPressed =
        event.ctrlKey || event.metaKey || event.shiftKey;

      this.context.handleMultiSelect(id, isModifierPressed);
    };

  private handleWrapperMouseDoubleClick = (id: string) => () => {
    if (!this.context) {
      return;
    }

    this.context.selectedItemIds
      .filter((itemId) => itemId !== id)
      .forEach(this.context.deselectItem);
  };

  private setWrapperHtmlEl = (id: string) => (el: HTMLElement | null) => {
    if (el) {
      this.itemWrapperHtmlEls[id] = el;
    } else {
      delete this.itemWrapperHtmlEls[id];
    }

    (
      this.props.itemWrapperHtmlElsRef as React.MutableRefObject<
        Record<string, HTMLElement>
      >
    ).current = this.itemWrapperHtmlEls;
  };

  public render() {
    const {
      itemsOnScreen,
      annotationsOnScreen,
      buttonsScale,
      playerMode,
      isThumbnailActive,
      hasStarted,
      isFunnel,
      // buttonsOffsetX,
      // buttonsOffsetY,
    } = this.props;

    // NOTE: items deselect is implemented in PlayButtonOverlay
    return (
      <VideoItemsContainerContext.Consumer>
        {({ setContainer }) => (
          <ContainerDiv ref={setContainer}>
            {/* <FullScreenShiftContainer
              left={buttonsOffsetX}
              top={buttonsOffsetY}
            > */}
            {(isThumbnailActive || hasStarted) && (
              <TransitionGroup>
                {itemsOnScreen.map((item) => (
                  <Transition
                    timeout={{ enter: 0, exit: isFunnel ? 0 : 300 }}
                    key={item.id}
                  >
                    {(state) => (
                      <VideoComponentRenderer
                        onTimelineItemClick={this.handleButtonClick}
                        onTimelineItemDismiss={this.handleDismiss}
                        animationState={state}
                        id={item.id}
                        buttonsScale={buttonsScale}
                        onWrapperMouseDown={this.handleWrapperMouseDown(
                          item.id
                        )}
                        onWrapperDoubleClick={this.handleWrapperMouseDoubleClick(
                          item.id
                        )}
                        wrapperRef={this.setWrapperHtmlEl(item.id)}
                      />
                    )}
                  </Transition>
                ))}
              </TransitionGroup>
            )}
            {/* </FullScreenShiftContainer> */}

            <AnnotationsGroup buttonsScale={buttonsScale}>
              {annotationsOnScreen.map((id) => (
                <Transition
                  timeout={{ enter: 0, exit: isFunnel ? 0 : 300 }}
                  key={id}
                >
                  {(state) => {
                    return (
                      <VideoComponentRenderer
                        onTimelineItemClick={this.handleButtonClick}
                        onTimelineItemDismiss={this.handleDismiss}
                        animationState={state}
                        id={id}
                        buttonsScale={buttonsScale}
                        onWrapperMouseDown={this.handleWrapperMouseDown(id)}
                        wrapperRef={this.setWrapperHtmlEl(id)}
                        onWrapperDoubleClick={this.handleWrapperMouseDoubleClick(
                          id
                        )}
                      />
                    );
                  }}
                </Transition>
              ))}
            </AnnotationsGroup>

            {[PlayerMode.TIMELINE_EDITOR, PlayerMode.THUMBNAIL_EDITOR].includes(
              playerMode
            ) && (
              <>
                <GuideLinesContainer />
                <RectangleSelect />
              </>
            )}
          </ContainerDiv>
        )}
      </VideoItemsContainerContext.Consumer>
    );
  }
}

const mapStateToProps = (state: IPlayerRootReducerShape, props: IProps) => {
  const itemsOnScreen = getItemsOnScreen(state) as ITimelineItem[];

  const annotationsOnScreen = itemsOnScreen.filter(
    (x) => x.type === 'annotation' || x.type === 'imageAnnotation'
  );

  const itemsOnScreenWithoutAnnotations = itemsOnScreen.filter(({ type }) => {
    return type !== 'annotation' && type !== 'imageAnnotation';
  });

  let buttonsOffsetX = 0;
  let buttonsOffsetY = 0;

  if (state.dimensions.isFullScreen) {
    // At the moment of writing this code dimensions.videoDimensions didn't update correctly
    // However, as full screen has no border, then containerDimensions should be equal to videoDimensions
    // and containerDimensions updated correctly!
    buttonsOffsetX =
      (window.innerWidth - state.dimensions.containerDimensions.width) >> 1;
    buttonsOffsetY =
      (window.innerHeight - state.dimensions.containerDimensions.height) >> 1;
  }

  return {
    buttonsOffsetX,
    buttonsOffsetY,
    isThumbnailActive:
      state.thumbnail.isVisible &&
      props.playerMode !== PlayerMode.TIMELINE_EDITOR,
    hasStarted: state.videoState.hasStarted,
    allItemsOnScreen: itemsOnScreen,
    itemsOnScreen: itemsOnScreenWithoutAnnotations,
    annotationsOnScreen: annotationsOnScreen
      .sort((a, b) => a.startSecond - b.startSecond)
      .map((item) => item.id),
    isFunnel: !!state.sourceConfiguration?.current?.funnelId,
  };
};

const hoc = connect(mapStateToProps);

export const VideoItemsContainer = hoc(VideoItemsContainerComp);
