import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.sass';
import { animateDashOffset } from './animateDashOffset';

export interface ILoaderProps {
  className?: string;
  color?: string;
  screenCenter?: boolean;
  blockCenter?: boolean;
  isVisible?: boolean;
  delay?: number;
}

interface IState {
  isShowing: boolean;
}

export const LoadingIndicator = ({
  color = '#008EFF',
  className,
}: {
  color?: string;
  className?: string;
}) => {
  const [svgPathElement, setSvgPathElement] = useState<SVGPathElement | null>(
    null
  );

  useEffect(() => {
    if (!svgPathElement) return;
    const animation = animateDashOffset(svgPathElement);

    return () => {
      animation?.clear();
    };
  }, [svgPathElement]);

  return (
    <div
      className={cx(className, styles.loader)}
      // @ts-ignore
      style={{ '--path': color, display: 'none' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 86 80"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <path
          ref={setSvgPathElement}
          className="voomly-loader-path"
          d="M100,140v68l57-34-57-34Z"
          transform="translate(-94-134)"
          fill="none"
          stroke={color}
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="145,76,145,76"
          strokeDashoffset="0"
        />
      </svg>
    </div>
  );
};

export class Loader extends React.PureComponent<ILoaderProps, IState> {
  private timeoutHandle?: number;

  constructor(props: ILoaderProps) {
    super(props);

    this.state = {
      isShowing: typeof props.delay === 'undefined',
    };
  }

  public componentDidMount() {
    const { delay } = this.props;

    if (typeof delay !== 'undefined') {
      this.timeoutHandle = window.setTimeout(() => {
        this.setState({
          isShowing: true,
        });
      }, delay);
    }
  }

  public componentWillUnmount() {
    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle);
    }
  }

  render() {
    const {
      className,
      color = '#008EFF',
      screenCenter,
      blockCenter,
      isVisible = true,
    } = this.props;

    const { isShowing } = this.state;

    if (!isShowing) return null;

    return (
      <div
        className={cx(className, styles.wrapper, {
          [styles.screenCenter]: screenCenter,
          [styles.blockCenter]: blockCenter,
        })}
      >
        {isVisible && <LoadingIndicator color={color} />}
      </div>
    );
  }
}
