import { EventEmitter } from 'eventemitter3';
import { Dispatch } from 'redux';
import {
  requestPause,
  requestSeek,
  requestPlay,
} from './store/videoState/actions';

export type EventTypes = {
  unlock: { password: string };
  timeUpdate: { time: number };
};

export type IPlayerApiEventName = keyof EventTypes;

export class PlayerExternalAPI {
  private eventEmitter = new EventEmitter();
  private dispatch?: Dispatch;

  register = (dispatch: Dispatch) => {
    this.dispatch = dispatch;
  };

  isInitialized = () => !!this.dispatch;

  seek = (time: number) => {
    if (!this.dispatch) {
      console.error('Player is not initialized');
      return;
    }

    this.dispatch(requestSeek({ time, type: 'toTime' }));
  };

  play = (time: number) => {
    if (!this.dispatch) {
      console.error('Player is not initialized');
      return;
    }

    this.dispatch(requestPlay({ time }));
  };

  pause = () => {
    if (!this.dispatch) {
      console.error('Player is not initialized');
      return;
    }

    this.dispatch(requestPause({}));
  };

  on = <N extends IPlayerApiEventName>(
    name: N,
    callback: (ev: EventTypes[N]) => void
  ) => {
    this.eventEmitter.on(name, callback);
  };

  emit = <N extends IPlayerApiEventName>(name: N, ev: EventTypes[N]) => {
    this.eventEmitter.emit(name, ev);
  };
}
