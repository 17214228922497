import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { ToggleChapters } from './ToggleChapters/ToggleChapters';
import ClickForSound from './ClickForSound/ClickForSound';
import ProgressBar from './ProgressBar/ProgressBar';
import { PlayButtonOverlay } from './PlayButtonOverlay/PlayButtonOverlay';
import TogglePlayButton from './TogglePlayButton/TogglePlayButton';
import ToggleFullscreen from './ToggleFullscreen/ToggleFullscreen';
import PlayPauseOverlay from './PlayPauseOverlay/PlayPauseOverlay';
import SelectSpeed from './SelectSpeed/SelectSpeed';
import Volume from './Volume/Volume';
import Logo from './Logo/Logo';
import { getStyles } from './skins/getStyles';
import styles from './controls.module.sass';
import RewatchButton from './RewatchButton/RewatchButton';
import { IDefaultPropTypes, PlayerMode } from '../types/defaultPropTypes';
import ToggleCompactControls from './ToggleCompactControls';
import {
  selectButtonsScale,
  selectContainerWidth,
  selectIsFullScreen,
} from '../../store/containerDimensions/selectors';
import BackButton from './BackButton/BackButton';
import {
  getAreControlsVisible,
  getIsCursorVisible,
} from '../../store/videoControls/selectors';
import { useMouseHandlers } from '../../contexts/MouseMoveContext';
import { SelectQuality } from './SelectQuality/SelectQuality';
import { usePlayerSkin } from './skins/PlayerSkinContext';

const Controls = ({
  player,
  file,
  playerMode,
  isOutSide,
  allControlsAreAlwaysVisible,
}: IDefaultPropTypes & {
  isOutSide?: boolean;
  allControlsAreAlwaysVisible?: boolean;
}) => {
  const skin = usePlayerSkin();
  const mouseHandlers = useMouseHandlers();
  const controlBarHandler = useMouseHandlers('controlBar');

  const isFullScreen = useSelector(selectIsFullScreen);
  const width = useSelector(selectContainerWidth);
  const buttonsScale = useSelector(selectButtonsScale);
  const isCursorVisible = useSelector(getIsCursorVisible);
  const areControlsVisible = useSelector(getAreControlsVisible);

  const {
    controls: {
      showPlayBar,
      showFullscreenButton,
      showSelectSpeedButton,
      showVolumeButton,
      showSelectQualityButton,
    },
    chapters,
  } = player;

  const hasControlButtons =
    showFullscreenButton ||
    showSelectSpeedButton ||
    showSelectQualityButton ||
    showVolumeButton ||
    chapters.enabled;

  const defaultProps: IDefaultPropTypes = {
    player,
    file,
    playerMode,
    buttonsScale,
    allControlsAreAlwaysVisible,
  };

  const borderSkinEnabled = player.appearance.borderSkinEnabled;
  const isControlsForBorder = isOutSide && borderSkinEnabled;
  const shouldShowPlayButtonOverlay =
    allControlsAreAlwaysVisible ||
    ![
      PlayerMode.TIMELINE_EDITOR,
      PlayerMode.THUMBNAIL_EDITOR,
      PlayerMode.MINI_PLAYER,
    ].includes(playerMode);

  const focusOnClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget.focus();
  }, []);

  if (isOutSide && !borderSkinEnabled) return null;
  if (!isOutSide && borderSkinEnabled && !isFullScreen) return null;
  if (isControlsForBorder && isFullScreen) return null;

  const isCompactStyle = !isFullScreen && width < 300;

  return (
    <div
      data-skin={skin.key}
      className={cx(styles.voomlyPlayerControls, {
        [styles.isFullScreen]: isFullScreen,
        [styles.hideCursor]: !isCursorVisible,
      })}
      {...mouseHandlers}
      tabIndex={0}
      onClick={focusOnClick}
    >
      <BackButton {...defaultProps} />
      <ClickForSound {...defaultProps} />
      <RewatchButton {...defaultProps} />

      {/* Overlays */}
      {shouldShowPlayButtonOverlay && (
        <div>
          <PlayButtonOverlay {...defaultProps} />
          <PlayPauseOverlay {...defaultProps} />
        </div>
      )}

      {/* The bottom control bar */}
      {showPlayBar && (
        <div
          className={cx(styles.root, skin.classes.controls__root, {
            [styles.visible]: areControlsVisible,
            [skin.classes.noControlButtons]: !hasControlButtons,
            [skin.classes.compact]: isCompactStyle,
          })}
          style={getStyles('controls__root', skin, player)}
          {...controlBarHandler}
        >
          {isCompactStyle ? (
            <ToggleCompactControls
              config={player}
              firstLayerContent={<ProgressBar {...defaultProps} />}
              secondLayerContent={
                playerMode !== PlayerMode.MINI_PLAYER ? (
                  <>
                    <Volume {...defaultProps} />
                    <SelectSpeed {...defaultProps} />
                    <SelectQuality {...defaultProps} />
                    <ToggleChapters {...defaultProps} />
                    <ToggleFullscreen {...defaultProps} />
                    <Logo {...defaultProps} />
                  </>
                ) : undefined
              }
            />
          ) : (
            <>
              <TogglePlayButton {...defaultProps} />
              <ProgressBar {...defaultProps} />

              {playerMode !== PlayerMode.MINI_PLAYER ? (
                <>
                  {hasControlButtons && (
                    <div
                      className={cx(
                        styles.buttons,
                        skin.classes.controls__buttons
                      )}
                      style={getStyles('controls__buttons', skin, player)}
                    >
                      <Volume {...defaultProps} />
                      <SelectSpeed {...defaultProps} />
                      <SelectQuality {...defaultProps} />
                      <ToggleChapters {...defaultProps} />
                      <ToggleFullscreen {...defaultProps} />
                    </div>
                  )}

                  <Logo {...defaultProps} />
                </>
              ) : undefined}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Controls;
