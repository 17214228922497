// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__qVe-4{display:flex;align-items:center;justify-content:center;padding:0;flex:1 1;height:100%}.styles_wrapper__qVe-4.styles_screenCenter__2P5gJ{position:fixed;top:50%;left:50%;transform:translate(-50%, -50%)}.styles_wrapper__qVe-4.styles_blockCenter__2zHDB{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.styles_loader__S8dN2{--path: var(--azure-500);--duration: 2s;width:48px;height:44px;position:relative;left:10px;display:block !important}.styles_loader__S8dN2 svg{display:block;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Loader/styles.module.sass"],"names":[],"mappings":"AAAA,uBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,SAAA,CACA,QAAA,CACA,WAAA,CACA,kDACE,cAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACF,iDACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAEJ,sBACE,wBAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,SAAA,CACA,wBAAA,CACA,0BACE,aAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".wrapper\n  display: flex\n  align-items: center\n  justify-content: center\n  padding: 0\n  flex: 1\n  height: 100%\n  &.screenCenter\n    position: fixed\n    top: 50%\n    left: 50%\n    transform: translate(-50%,-50%)\n  &.blockCenter\n    position: absolute\n    top: 50%\n    left: 50%\n    transform: translate(-50%,-50%)\n\n.loader\n  --path: var(--azure-500)\n  --duration: 2s\n  width: 48px\n  height: 44px\n  position: relative\n  left: 10px\n  display: block!important\n  svg\n    display: block\n    width: 100%\n    height: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__qVe-4",
	"screenCenter": "styles_screenCenter__2P5gJ",
	"blockCenter": "styles_blockCenter__2zHDB",
	"loader": "styles_loader__S8dN2"
};
export default ___CSS_LOADER_EXPORT___;
