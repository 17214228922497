import { DeepPartial } from 'ts-essentials';
import { IFunnelsRaw, INodeRaw } from '../types/funnels';
import {
  autofixPlayerThumbnail,
  autofixPlayerTemplateConfig,
  autofixShareVideo,
} from './autofixPlayerWithVideoConfig';
import {
  autofixPlayerChapters,
  autofixThumbnailItems,
  autofixTimelineItems,
} from './autofixTimeline';

const autofixBuilderData = (
  builderData: DeepPartial<INodeRaw['builderData']> | undefined
): INodeRaw['builderData'] => {
  return {
    x: builderData?.x ?? Math.floor(Math.random() * 600),
    y: builderData?.y ?? Math.floor(Math.random() * 600),
    color: builderData?.color ?? Math.floor(Math.random() * 0xffffff),
  };
};

const autofixNodePlayer = (
  player: DeepPartial<INodeRaw>['player']
): INodeRaw['player'] => {
  return {
    ...player,
    chapters: autofixPlayerChapters(player?.chapters),
  };
};

const autofixFunnelNodes = (
  nodes: DeepPartial<INodeRaw[]> | undefined
): INodeRaw[] => {
  if (!nodes) return [];

  return nodes
    .filter((node) => Boolean(node))
    .map((n) => {
      const node = n!;

      return {
        id: node.id!,
        builderData: autofixBuilderData(node.builderData),
        player: autofixNodePlayer(node.player),
        videoId: node.videoId!,
        timelineItems: autofixTimelineItems(node.timelineItems),
      };
    });
};

export const autofixFunnel = (
  funnel: DeepPartial<IFunnelsRaw>
): IFunnelsRaw => {
  return {
    id: funnel.id!,
    name: funnel.name ?? 'missing name',
    description: funnel.description,
    nodes: autofixFunnelNodes(funnel.nodes),
    startNodeId: funnel.startNodeId,
    playerConfig: autofixPlayerTemplateConfig(funnel.playerConfig),
    playerTemplateId: funnel.playerTemplateId,
    thumbnail: autofixPlayerThumbnail(funnel.thumbnail),
    thumbnailItems: autofixThumbnailItems(funnel.thumbnailItems),
    shareVideo: autofixShareVideo(funnel.shareVideo),
    updatedAt: funnel.updatedAt || '',
    createdAt: funnel.createdAt || '',
    deletedAt: funnel.deletedAt || '',
  };
};
