import React from 'react';
import missedVideo from '../../videos/missed-video.mp4';
import styled from 'styled-components';
import style from 'styled-theming';

export const getMissedVideoBackground = style('mode', {
  light: '#c8c9d0',
  dark: '#5d5e62',
});

const StyledBrowseVideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${getMissedVideoBackground};
  padding: 10px;
  overflow: hidden;

  /* missed-video.mp4 has own background that displayed differently in FF  */

  ${() => `
    @-moz-document url-prefix() {
      background: #bfc3c8;
    }
  `}
`;

const MissedVideoCaption = styled.div`
  max-width: 400px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const MissedVideoContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  overflow: hidden;
  height: 100%;
  max-height: 200px;

  padding: 0.25rem;
  border-radius: 0.5rem;

  background: #c8c9d0;

  video {
    height: 100%;
    width: 100%;
    position: relative;
  }
`;

export const MissedVideo: React.FC<{ caption: React.ReactNode }> = ({
  caption,
  children,
}) => {
  return (
    <StyledBrowseVideoWrapper>
      <MissedVideoCaption>{caption}</MissedVideoCaption>

      <MissedVideoContainer>
        <video src={missedVideo} loop={true} autoPlay muted />
      </MissedVideoContainer>

      {children}
    </StyledBrowseVideoWrapper>
  );
};
