import { Channel } from 'redux-saga';
import { createStandardAction } from 'typesafe-actions';
import { IMouseEvent } from './controlVisibilitySaga';
import { PlayerMode } from '../../components/types/defaultPropTypes';

export const addControlsStayVisibleBy = createStandardAction(
  'videoControls/ADD_CONTROLS_STAY_VISIBLE_BY'
)<'SELECT_SPEED' | 'SELECT_QUALITY'>();
export const removeControlsStayVisibleBy = createStandardAction(
  'videoControls/REMOVE_CONTROLS_STAY_VISIBLE_BY'
)<'SELECT_SPEED' | 'SELECT_QUALITY'>();
export const showControls = createStandardAction(
  'videoControls/SHOW_CONTROLS'
)();
export const hideControls = createStandardAction(
  'videoControls/HIDE_CONTROLS'
)();
export const changeClickForSoundVisibility = createStandardAction(
  'videoControls/CHANGE_CLICK_FOR_SOUND_VISIBILITY'
)<{ isVisible: boolean }>();

export const changeLogoVisibility = createStandardAction(
  'videoControls/CHANGE_LOGO_VISIBILITY'
)<{ isVisible: boolean }>();

export const registerMouseChannel = createStandardAction(
  'videoControls/REGISTER_MOUSE_CHANNEL'
)<{ ch: Channel<IMouseEvent> | undefined; playerMode: PlayerMode }>();
