import React, { useEffect, useMemo, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { Ratio } from '@voomly/ui/player-deps';
import { IPlayerTemplate } from '../../../types/player';
import { Loader } from '../../Loader';
import { PlayerMode } from '../../types/defaultPropTypes';
import {
  findAndImportPlayerSkin,
  importAllPlayerSkins,
} from './findAndImportPlayerSkin';
import { IPlayerSkin, IPlayerSkinKey } from './types';

export function cancellablePromise<T>(promise: Promise<T>) {
  const isCancelled = { value: false };
  const wrappedPromise = new Promise<T>((res, rej) => {
    promise
      .then((d) => {
        return isCancelled.value ? rej(isCancelled) : res(d);
      })
      .catch((e) => {
        rej(isCancelled.value ? isCancelled : e);
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true;
    },
  };
}

const useLoadPlayerSkin = (player: IPlayerTemplate, loadAll: boolean) => {
  let skinKey = player.appearance.controlBarSkin;

  // @ts-ignore
  if (skinKey === 'flat') {
    console.error(
      'Skin "flat" is deprecated, use "default" instead. Backend migration required.'
    );
    skinKey = 'default';
  }

  const [isLoading, setIsLoading] = useState(true);
  const [skin, setSkin] = useState<IPlayerSkin | undefined>(undefined);
  const skinsCache = useMemo(() => new Map<IPlayerSkinKey, IPlayerSkin>(), []);

  useEffect(() => {
    if (!loadAll) return;

    const { promise, cancel } = cancellablePromise(importAllPlayerSkins());

    if (skinsCache.has(skinKey)) {
      setSkin(skinsCache.get(skinKey));
      return;
    }

    (async () => {
      try {
        setIsLoading(true);
        const skins = await promise;
        skins.default.forEach((skin: IPlayerSkin) => {
          skinsCache.set(skin.key, skin);
        });
        setSkin(skinsCache.get(skinKey));
        setIsLoading(false);
      } catch (e) {
        // if the promise was cancelled, we don't want to do anything
        setIsLoading(false);
      }
    })();

    return () => {
      cancel();
    };
  }, [skinKey, loadAll, skinsCache]);

  useEffect(() => {
    if (loadAll) return;

    const { promise, cancel } = cancellablePromise(
      findAndImportPlayerSkin(skinKey)
    );

    if (skinsCache.has(skinKey)) {
      setSkin(skinsCache.get(skinKey));
      return;
    }

    (async () => {
      try {
        setIsLoading(true);
        const skin = await promise;
        skinsCache.set(skinKey, skin);
        setSkin(skin);
        setIsLoading(false);
      } catch (e) {
        // if the promise was cancelled, we don't want to do anything
        setIsLoading(false);
      }
    })();

    return () => {
      cancel();
    };
  }, [skinKey, loadAll, skinsCache]);

  return useMemo(() => [skin, isLoading || !skin] as const, [skin, isLoading]);
};

interface IPlayerSkinContext {
  skin: IPlayerSkin | undefined;
}

export const PlayerSkinContext = createContext<IPlayerSkinContext>({
  skin: undefined,
});

// NOTE: skin always should be loaded
export const playerSkinSelector = (skinContext: IPlayerSkinContext) =>
  skinContext.skin!;

export const usePlayerSkin = () => {
  return useContextSelector(PlayerSkinContext, playerSkinSelector);
};

export const PlayerSkinContextProvider = ({
  player,
  playerMode,
  loaderRatio,
  children,
}: {
  player: IPlayerTemplate;
  playerMode: PlayerMode;
  loaderRatio: number;
  children: React.ReactNode;
}) => {
  const [skin, isLoading] = useLoadPlayerSkin(
    player,
    playerMode === PlayerMode.TIMELINE_EDITOR
  );

  if (isLoading) {
    return (
      <Ratio ratio={loaderRatio}>
        <Loader
          color={
            player && player.appearance.bgColorEnabled
              ? player.appearance.bgColor
              : undefined
          }
        />
      </Ratio>
    );
  }

  return (
    <PlayerSkinContext.Provider value={{ skin }}>
      {children}
    </PlayerSkinContext.Provider>
  );
};
