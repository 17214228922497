import React from 'react';
import * as ReactDOM from 'react-dom';
import './reset.skipScopify.sass';
import './bootstrapInterceptors';
import {
  PlayerExternalAPI,
  IPlaybackSettings,
  getLastVisitWatchSeconds,
  VideoItemsContainerProvider,
  PlayerContainerFromEmbedVideoId,
  PlayerMode,
  FunnelsPlayer,
} from '@voomly/player';
import { createGlobalStyle } from 'styled-components';

const normalizeRatio = (ratio: string) => {
  if (!ratio) return undefined;

  if (ratio.includes(':')) {
    const split = ratio.split(':');
    return parseInt(split[0], 10) / parseInt(split[1], 10);
  } else {
    return parseFloat(ratio);
  }
};

const CSSColorVariables = createGlobalStyle`
  .voomly-embed {
    // NOTE: Voomly root is inline-flex element, it makes small gap under the player (about 4px)
    // since it's inline element, we can remove it by setting font-size to 0
    // it's safe because Voomly root has own "font-size: 16px" (libs/player/src/lib/components/VideoLayout/videoLayout.module.sass)
    font-size: 0!important;
    --azure-500: #008EFF;
  }
`;

const App = ({
  id,
  type,
  ratio,
  playerExternalAPI,
}: {
  type: 'v' | 'f';
  id: string;
  ratio: string;
  playerExternalAPI: PlayerExternalAPI;
}) => {
  const initialPlaybackSettings: IPlaybackSettings = {
    currentTime: 0,
    lastVisitWatchedSeconds: getLastVisitWatchSeconds(id),
  };

  const normalizedRatio = normalizeRatio(ratio) || 1.7777;

  if (type === 'v') {
    return (
      <VideoItemsContainerProvider>
        <PlayerContainerFromEmbedVideoId
          ratio={normalizedRatio}
          embedVideoId={id}
          playerMode={PlayerMode.NORMAL}
          playbackSettings={initialPlaybackSettings}
          isRememberDismissEnabled={true}
          playerExternalAPI={playerExternalAPI}
        />
      </VideoItemsContainerProvider>
    );
  }

  if (type === 'f') {
    return (
      <FunnelsPlayer
        funnelId={id}
        ratio={normalizedRatio}
        isRememberDismissEnabled={true}
      />
    );
  }

  throw new Error(`Unknown playable type: ${type}`);
};

const attachedPlayerPairs: Array<[HTMLDivElement, PlayerExternalAPI]> = [];

// When SSR player can be rendered on server,
// so keep the map of flags if the player was rendered on client
const renderedVoomlyPlayers: { [id: string]: boolean } = {};

const attachVoomlyPlayer = (
  element: HTMLDivElement,
  id: string,
  type: 'v' | 'f' = 'v',
  ratio: string | '16:9' | 'auto' = 'auto'
) => {
  const loaders = element.getElementsByClassName('voomly-embed-loader');

  if (loaders && loaders.length > 0) {
    let i = loaders.length;
    while (i--) {
      const element = loaders[i];
      element.remove();
    }
  }

  if (element.children.length > 0 && renderedVoomlyPlayers[id]) {
    return;
  }

  const playerExternalAPI = new PlayerExternalAPI();

  ReactDOM.render(
    <>
      <CSSColorVariables />
      <App
        id={id}
        type={type}
        ratio={ratio}
        playerExternalAPI={playerExternalAPI}
      />
    </>,
    element
  );

  attachedPlayerPairs.push([element, playerExternalAPI]);
  renderedVoomlyPlayers[id] = true;

  return playerExternalAPI;
};

const attachEmbedPlayers = () => {
  const embedVideos = document.getElementsByClassName('voomly-embed');
  let i = embedVideos.length;
  while (i--) {
    const element = embedVideos[i];
    const id = element.getAttribute('data-id');
    const type = element.getAttribute('data-type') || 'v';
    const ratio = element.getAttribute('data-ratio') || 'auto';
    if (type !== 'v' && type !== 'f') {
      throw new Error(`Wrong type!`);
    }

    if (!id) {
      console.error('Missing id!');
    } else {
      attachVoomlyPlayer(element as HTMLDivElement, id, type, ratio);
    }
  }
};

// @ts-ignore
window.voomlyEmbedPlayerApp = {
  attach: attachVoomlyPlayer,
  attachAll: attachEmbedPlayers,
};
