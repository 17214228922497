import { delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  toggleSwallowPlayPauseOverlay,
  toggleAlternativeDurationLine,
} from '../../miscScreenObjects/actions';
import { promoteNextVideoToCurrent } from '../../sourceConfiguration/actions';
import {
  getChangingVideo,
  getIsVideoChanging,
} from '../../sourceConfiguration/selectors';
import { resetHighlights } from '../../videoItems/actions';
import {
  videoIsReady,
  requestVideoBuffer,
  videoBuffered,
  requestAutoplay,
  requestSpeedChange,
} from '../actions';
import { getIsVideoMuted, getVideoState } from '../selectors';

// When next video requested:
// 1. We unsubscribe from the current video api
// 2. We subscribe to the new event api
// 3. We await when this video will be ready(handleNextVideoReady)
// 4. When it's ready, we buffer first n seconds. Also, during this time
//    we show the blurry overlay
// 5. When buffer ready, we replace current file/player config with the new onw(handleVideoBuffered)

function* handleNextVideoReady(_action: ReturnType<typeof videoIsReady>) {
  const nextVideo = getChangingVideo(yield select());

  if (!nextVideo) return;

  // console.log('next video is ready: ', this.videoApi);
  yield put(toggleSwallowPlayPauseOverlay(true));

  // Preload first N seconds of the video OR have a delay if it's too fast
  // That's to show the animation of the selected option
  yield put(
    requestVideoBuffer({
      time: nextVideo.playbackSettings?.currentTime || 0,
      initiatedBy: 'nextVideoPlay',
    })
  );
}

function* handleVideoBuffered(action: ReturnType<typeof videoBuffered>) {
  if (action.payload.initiatedBy !== 'nextVideoPlay') return;

  if (!getIsVideoChanging(yield select())) {
    console.error("Wanted to switch to next video, but it' empty!");
    return;
  }

  const videoState = getVideoState(yield select());

  yield put(toggleAlternativeDurationLine(undefined));
  yield put(promoteNextVideoToCurrent());
  yield put(resetHighlights());

  // Copy video api preference(like speed) from previous video
  // by taking current values from store and passing to actions
  yield put(requestSpeedChange({ value: videoState.speed }));

  yield put(
    requestAutoplay({
      muted: getIsVideoMuted(yield select()),
    })
  );

  yield delay(100);

  yield put(toggleSwallowPlayPauseOverlay(false));
}

export function* nextVideoSaga() {
  yield takeEvery(videoIsReady, handleNextVideoReady);
  yield takeEvery(videoBuffered, handleVideoBuffered);
}
