import { DeepPartial } from 'ts-essentials';
import { difference, cloneDeep, pick } from 'lodash-es';
import {
  autofixPlayerChapters,
  autofixThumbnailItems,
  autofixTimelineItems,
} from './autofixTimeline';
import { Optional } from 'utility-types';
import { IPlayerTemplate, IPlayerWithVideo } from '../types/player';
import {
  DEFAULT_PLAYER_DATA,
  emptyPlayerConfig,
} from '../defaults/emptyPlayerConfig';

const copyObjectOr = <T>(original: DeepPartial<T>, myDefault: T): T => {
  const result = cloneDeep(myDefault);

  if (!original) return result;

  const defaultKeys = Object.keys(result);
  const originalKeys = Object.keys(original);
  const missingKeysInDefault = difference(originalKeys, defaultKeys);

  const extraData = pick(original, missingKeysInDefault);
  defaultKeys.forEach((key) => {
    // @ts-ignore
    if (typeof original[key] !== 'undefined') {
      // @ts-ignore
      result[key] = original[key];
    }
  });

  return {
    ...result,
    ...extraData,
  };
};

const autofixPlayerAppearance = (
  appearance: DeepPartial<IPlayerTemplate['appearance']> | undefined
): IPlayerTemplate['appearance'] => {
  const defaultValue = emptyPlayerConfig.appearance;

  if (!appearance) return cloneDeep(defaultValue);

  return {
    ...copyObjectOr<IPlayerTemplate['appearance']>(appearance, defaultValue),
    customLogoUrl: appearance.customLogoUrl,
    customUrl: appearance.customUrl,
  };
};

const autoFixActionOnEnd = (
  action: DeepPartial<IPlayerTemplate['controls']['onEnd']> | undefined
): IPlayerTemplate['controls']['onEnd'] => {
  const defaultValue = emptyPlayerConfig.controls.onEnd;
  if (!action || !action.action) return cloneDeep(defaultValue);

  return action as IPlayerTemplate['controls']['onEnd'];
};

type IServerThumb = {
  useCustom: boolean;
  frameTime?: number;
  // almost always will be populated. Can be undefined during generation (show spinner in that case)
  frameUrl: string | undefined;
  customUrl: string | undefined;
};

export const autofixPlayerThumbnail = (
  thumbnail:
    | DeepPartial<IPlayerWithVideo['thumbnail']>
    | IServerThumb
    | undefined
): IPlayerWithVideo['thumbnail'] => {
  if (!thumbnail) {
    return;
  }

  if ('useCustom' in thumbnail) {
    if (thumbnail.useCustom) {
      if (!thumbnail.customUrl) return;

      return {
        type: 'customImage',
        url: thumbnail.customUrl,
      };
    } else {
      if (!thumbnail.frameUrl) return;

      return {
        type: 'frameFromVideo',
        frameTime: thumbnail.frameTime ?? 0,
        url: thumbnail.frameUrl,
      };
    }
  } else {
    return thumbnail as IPlayerWithVideo['thumbnail'];
  }
};

const autofixPlayerPasswordProtection = (
  protection: DeepPartial<IPlayerTemplate['passwordProtection']> | undefined
): IPlayerTemplate['passwordProtection'] => {
  const defaultValue = emptyPlayerConfig.passwordProtection;

  if (!protection) return cloneDeep(defaultValue);

  return {
    enabled: protection.enabled ?? defaultValue.enabled,
    password: protection.password,
    title: protection.title ?? defaultValue.title,
    enterPasswordText:
      protection.enterPasswordText || 'Please enter password to view video:',
    submitButtonText: protection.submitButtonText || 'Unlock',
    submitButtonLoadingText: protection.submitButtonLoadingText || 'Loading...',
    emptyPasswordErrorText:
      protection.emptyPasswordErrorText || 'Password is a required field',
    wrongPasswordErrorText:
      protection.wrongPasswordErrorText || 'Wrong password',
  };
};

const autofixPlayerControls = (
  controls: DeepPartial<IPlayerTemplate['controls']> | undefined
): IPlayerTemplate['controls'] => {
  const defaultValue = emptyPlayerConfig.controls;

  if (!controls) return cloneDeep(defaultValue);

  return {
    enableSoundButtonLocation: 'top-right',
    customPlayButtonUrl: controls.customPlayButtonUrl,
    onEnd: autoFixActionOnEnd(controls.onEnd),
    ...copyObjectOr<
      Optional<
        IPlayerTemplate['controls'],
        'onEnd' | 'enableSoundButtonLocation' | 'customPlayButtonUrl'
      >
    >(controls, defaultValue),
  };
};

export const autofixShareVideo = (
  shareVideo:
    | DeepPartial<IPlayerWithVideo['shareVideo'] | undefined>
    | undefined
): IPlayerWithVideo['shareVideo'] => {
  return {
    allowComments: false,
    logoInHeader: 'voomlyLogo',
    customLogoUrl: undefined,
    pageTheme: 'systemTheme',
    ...shareVideo,
  };
};

export const autofixPlayerTemplateConfig = (
  playerConfig: DeepPartial<IPlayerTemplate> | undefined
): IPlayerTemplate => {
  if (!playerConfig) {
    return cloneDeep(emptyPlayerConfig);
  }

  return {
    name: playerConfig.name ?? 'untitled player',
    createdAt: playerConfig.createdAt ?? '2010-01-01T00:00:00.000Z',
    updatedAt: playerConfig.updatedAt ?? '2010-01-01T00:00:00.000Z',
    deletedAt: playerConfig.deletedAt ?? '2010-01-01T00:00:00.000Z',

    appearance: autofixPlayerAppearance(playerConfig.appearance),
    controls: autofixPlayerControls(playerConfig.controls),
    passwordProtection: autofixPlayerPasswordProtection(
      playerConfig.passwordProtection
    ),

    captions: { ...DEFAULT_PLAYER_DATA.captions, ...playerConfig.captions },
  };
};

export const autofixPlayerWithVideoConfig = (
  playerConfig: DeepPartial<IPlayerWithVideo>
): IPlayerWithVideo => {
  const basePlayer = autofixPlayerTemplateConfig(playerConfig);

  if (!playerConfig.videoId) {
    throw new Error('videoId is required');
  }

  return {
    ...basePlayer,
    videoId: playerConfig.videoId,
    timelineItems: autofixTimelineItems(playerConfig.timelineItems),
    thumbnailItems: autofixThumbnailItems(playerConfig.thumbnailItems),
    thumbnail: autofixPlayerThumbnail(playerConfig.thumbnail),
    chapters: autofixPlayerChapters(playerConfig.chapters),
    shareVideo: autofixShareVideo(playerConfig.shareVideo),
    // Note: Don't forget to add optional fields here
    // Optional
    playerTemplateId: playerConfig.playerTemplateId,
  };
};
