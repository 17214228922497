import { groupBy, omit } from 'lodash-es';
import { Channel } from 'redux-saga';
import { all, call, delay, flush, take } from 'redux-saga/effects';
import { PromiseType } from 'utility-types';
import {
  createSessionAnalyticsEvents,
  fetchSessionId,
} from '../../../api/analytics';
import {
  eAnalyticsEventType,
  IAnalyticsSessionEvent,
} from '../../../types/analytics';
import { IConfigIds } from '../../sourceConfiguration/selectors';
import { getUserInfo } from './getUserInfo';

const finishedSessionIds: string[] = [];

export type ISessionEventsWithConfig = {
  ev: IAnalyticsSessionEvent;
  config: IConfigIds;
};

// // After finish event happened we can't send new events anymore(API will return error)
const filterEventsAfterFinish = (events: IAnalyticsSessionEvent[]) => {
  const evs: IAnalyticsSessionEvent[] = [];

  for (const ev of events) {
    evs.push(ev);

    if (ev.name === eAnalyticsEventType.finish) break;
  }

  return evs;
};

function* sendSessionEvents(
  sessions: Record<string, string>,
  events: ISessionEventsWithConfig[],
  byBeacon = false
) {
  const toResend: ISessionEventsWithConfig[] = [];
  let shouldRecreateSession = false;

  // sends sessions to the correct sessionId
  const grouped = groupBy(events, (ev) => ev.config.id);
  for (const [configId, events] of Object.entries(grouped)) {
    const sessionId = sessions[configId];
    if (!sessionId) {
      toResend.push(...events);

      continue;
    }

    if (finishedSessionIds[sessionId]) {
      continue;
    }

    if (events.length === 0) {
      continue;
    }

    const result: PromiseType<ReturnType<typeof createSessionAnalyticsEvents>> =
      yield call(createSessionAnalyticsEvents, {
        events: filterEventsAfterFinish(
          events.map(({ ev }) => omit(ev, 'config') as IAnalyticsSessionEvent)
        ),
        sessionId: sessions[configId],
        byBeacon: byBeacon,
      });

    if (
      result.success &&
      events.find((ev) => ev.ev.name === eAnalyticsEventType.finish)
    ) {
      finishedSessionIds.push(sessionId);
    }

    if (!result.success) {
      shouldRecreateSession = true;
      toResend.push(...events);
    }
  }

  return { toResend, shouldRecreateSession };
}

export function* eventsSenderSaga(ch: Channel<ISessionEventsWithConfig>) {
  let toResend: ISessionEventsWithConfig[] = [];
  let shouldRecreateSession = false;

  // key is config id, value is session id
  const sessions: Record<string, string> = {};

  try {
    while (true) {
      const events: ISessionEventsWithConfig[] = yield flush(ch);

      for (const event of [...events, ...toResend]) {
        if (!sessions[event.config.id] || shouldRecreateSession) {
          // if undefined returns it still will retry to fetch session id(in next call)
          sessions[event.config.id] = yield call(
            fetchSessionId,
            event.config.ids,
            getUserInfo()
          );
          shouldRecreateSession = false;
        }
      }

      const result = (yield all([
        call(sendSessionEvents, sessions, events, false),
        call(sendSessionEvents, sessions, toResend, false),
      ])) as {
        toResend: ISessionEventsWithConfig[];
        shouldRecreateSession: boolean;
      }[];

      toResend = result.flatMap(({ toResend }) => toResend);
      shouldRecreateSession = result.some(
        ({ shouldRecreateSession: resultShouldRecreateSession }) =>
          resultShouldRecreateSession
      );

      yield delay(5000);
    }
  } finally {
    // This method can be triggered on page leave, and here we can't make usual
    // ajax query, only beacons(they will be sent browser in background)
    // So that's why we can't take new session id, but we still can send
    // remaining events
    const events: ISessionEventsWithConfig[] = yield take(ch);

    yield all([
      call(sendSessionEvents, sessions, events, true),
      call(sendSessionEvents, sessions, toResend, true),
    ]);
  }
}
