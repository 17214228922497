import {
  ITimelineAnnotation,
  ITimelineButton,
  ITimelineEmail,
  ITimelineGrid,
  ITimelineHotspot,
  ITimelineHTML,
  ITimelineImage,
  ITimelineImageAnnotation,
  ITimelineShareGate,
  ITimelineText,
  ITimelineTurnstile,
} from '../types';
import { emptyPlayerConfig } from './emptyPlayerConfig';

export type WithoutBaseProps<T> = Omit<
  T,
  'endSecond' | 'startSecond' | 'id' | 'zIndex' | 'type'
>;

export const defaultTimelineText: WithoutBaseProps<ITimelineText> = {
  label: 'Text',
  font: '',
  fontSize: 14,
  fontColor: '#000',
  fontStyle: ['align-left'],
  verticalAlign: 'vertical-align-top',
  borderSize: 1,
  borderColor: '#fff',
  x: 0,
  y: 0,
  width: 100,
  height: 25,
};

export const defaultTimelineButton: WithoutBaseProps<ITimelineButton> = {
  label: 'Button',
  buttonColor: '#008eff',
  opacity: 100,
  rounded: 0,
  font: '',
  fontSize: 14,
  fontColor: '#ffffff',
  fontStyle: ['align-center'],
  verticalAlign: 'vertical-align-center',
  borderSize: 0,
  borderColor: '#ffffff',
  actionOnClick: { type: 'doNothing' },
  actionsOnEnter: [{ type: 'doNothing' }],
  actionsOnPreExit: [{ type: 'doNothing' }],
  x: 0,
  y: 0,
  width: 100,
  height: 40,
};

export const defaultTimelineEmail: WithoutBaseProps<ITimelineEmail> = {
  label: 'Email',
  pauseVideo: false,
  inputColor: '#fff',
  inputOpacity: 100,
  inputRounded: 0,
  inputBorder: 0,
  inputBorderColor: '',
  buttonColor: '#008EFF',
  buttonOpacity: 100,
  buttonRounded: 0,
  buttonBorder: 0,
  buttonBorderColor: '',
  email: '',
  x: 0,
  y: 0,
  width: 200,
  height: 50,
};

export const defaultTimelineGrid: WithoutBaseProps<ITimelineGrid> = {
  label: 'Grid',
  backgroundColor: '#000000',
  backgroundOpacity: 85,

  buttonsInRowCount: 2,
  buttonsCount: 6,
  buttonColor: emptyPlayerConfig.appearance.bgColor,
  buttonTextColor: '#FFFFFF',
  buttonOpacity: 100,
  buttonRounded: 0,

  buttons: [],
  font: '',
  fontSize: 20,
  fontStyle: ['align-center'],
  fontColor: '#FFFFFF',

  appearanceAnimation: 'fade',
  actionsOnEnter: [{ type: 'pause' }],
  actionsOnPreExit: [{ type: 'doNothing' }],

  isRequired: false,

  pauseAutoResumeIn: 10,

  paddingBetweenButtons: 5,
  paddingButtonContainer: 5,

  buttonFontSize: 16,
};

export const defaultTimelineHotspot: WithoutBaseProps<ITimelineHotspot> = {
  label: 'Hotspot',
  pauseVideo: false,
  x: 0,
  y: 0,
  width: 200,
  height: 200,
};

export const defaultTimelineHTML: WithoutBaseProps<ITimelineHTML> = {
  label: 'HTML',
  body: '',
};

export const defaultTimelineImage: WithoutBaseProps<ITimelineImage> = {
  label: 'Image',
  image: { type: 'notSet' },
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  keepAspectRatio: true,
  aspectRatio: 1,
  opacity: 100,
  actionOnClick: {
    type: 'doNothing',
  },
  actionsOnEnter: [{ type: 'doNothing' }],
  actionsOnPreExit: [{ type: 'doNothing' }],
};

export const defaultTimelineAnnotation: WithoutBaseProps<ITimelineAnnotation> =
  {
    label: 'Annotation',
    backgroundColor: '#008EFF',
    opacity: 100,
    font: '',
    fontSize: 14,
    fontStyle: ['align-left'],
    fontColor: '#fff',
    borderSize: 0,
    borderColor: '#ffffff',
    rounded: 0,
    actionOnClick: {
      type: 'doNothing',
    },
  };

export const defaultTimelineImageAnnotation: WithoutBaseProps<ITimelineImageAnnotation> =
  {
    label: 'Image Annotation',
    image: { type: 'notSet' },
    width: 100,
    height: 80,
    keepAspectRatio: true,
    aspectRatio: 100 / 80,
    opacity: 100,
    actionOnClick: {
      type: 'doNothing',
    },
  };

export const defaultTimelineTurnstile: WithoutBaseProps<ITimelineTurnstile> = {
  label: 'Turnstile',
  backgroundColor: '#000',
  backgroundOpacity: 50,
  // Button submit
  buttonText: 'Submit',
  buttonFontSize: 14,
  buttonBackgroundColor: '#008eff',
  buttonTextColor: '#fff',
  buttonBorderRadius: 4,
  // Upper
  titleText: 'To watch this video, please fill out this form first!',
  titleFontSize: 20,
  titleFontStyle: [],
  titleFontColor: '#fff',
  // Lower
  subTitleText: 'We will never share your information with anyone.',
  subTitleFontSize: 16,
  subTitleFontStyle: [],
  subTitleFontColor: '#fff',
  // Form
  formEmailText: 'Email address',
  formLastNameText: 'Last Name',
  formFirstNameText: 'First name',

  emailRequiredText: 'Email address is a required field',
  emailValidationText: 'Enter a valid email address',
  failedToSubmitText:
    'Something went wrong, cannot submit the turnstile. Please contact support',
  skipText: 'Skip',

  isNameRequired: true,
  skippable: true,
};

export const defaultTimelineShareGate: WithoutBaseProps<ITimelineShareGate> = {
  label: 'Share Gate',
  backgroundColor: '#FFFFFF',
  buttonsBackgroundColor: '#008EFF',
  buttonsTextColor: '#FFFFFF',
  buttonsBorderRadius: 0,

  titleFontSize: 24,
  titleFontColor: '#000000',
  titleFontStyle: ['bold'],

  subTitleFontSize: 18,
  subTitleFontColor: '#000000',
  subTitleFontStyle: [],

  shareButtonOptions: ['facebook', 'twitter', 'linkedin'],

  titleText: 'Wait Just a Second',
  subTitleText: 'To continue, click a button below...',

  doneSharingText: 'Done Sharing?',
  continueText: 'Continue',
  backText: 'Back',
  skipText: 'Skip',

  design: 1,
};
