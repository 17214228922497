import * as React from 'react';
import memoizeOne from 'memoize-one';
import {
  DetachedStoreContext,
  IDetachedStoreContext,
} from './DetachedStoreContext';
import { noop } from 'lodash-es';
import { IThumbnailItem, ITimelineItem } from '../types';

interface IProps {
  children: React.ReactNode;
  timelineItems: ITimelineItem[];
  thumbnailItems: IThumbnailItem[];
}
export class DetachableStoreProviderForPlayer extends React.PureComponent<IProps> {
  private getContextValue = memoizeOne(
    (
      timelineItems: ITimelineItem[],
      thumbnailItems: IThumbnailItem[]
    ): IDetachedStoreContext => {
      return {
        timelineItems,
        thumbnailItems,
        detachedItemsById: {},
        detachItemFromStore: noop,
        detachAllItemsFromStore: noop,
        attachSelectedItemsToStore: noop,
        attachItemToStore: noop,
        attachAllItemsToStore: noop,
        updateItem: noop,
        detachSelectedItemsFromStore: noop,

        getAllSelectedItemsValuesByField: () => [],
        swapZIndexes: noop,
        selectedItemIds: [],
        updatePositionInTime: noop,
        handleMultiSelect: noop,
        handleMultiDeselect: noop,
        moveItem: noop,
        itemWrapperHtmlElsRef: React.createRef(),
        deselectItem: noop,

        handleRectangleSelectIntersection: noop,
        deselectAll: noop,

        snappedToTimeSeconds: undefined,
        snappedToX: undefined,
        snappedToY: undefined,
        isThumbnailEditorActive: false,
      };
    }
  );

  public render() {
    const { children, timelineItems, thumbnailItems } = this.props;

    return (
      <DetachedStoreContext.Provider
        value={this.getContextValue(timelineItems, thumbnailItems)}
      >
        {children}
      </DetachedStoreContext.Provider>
    );
  }
}
