import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { videoStateReducer } from './videoState/videoStateReducer';
import ctaFooterReducer from './ctaFooter';
import chaptersReducer from './chapters';
import containerDimensionsReducer from './containerDimensions';
import thumbnailReducer from './thumbnail';
import playPauseOverlayReducer from './playPauseOverlay';
import askToResumeOnVisitOverlayReducer from './askToResumeOnVisitOverlay';
import { videoItemsReducer } from './videoItems/videoItemsReducer';
import miscScreenObjectsReducer from './miscScreenObjects';
import sourceConfigurationReducer from './sourceConfiguration';
import { nodeEnv } from '../config/env';
import { videoControlsReducer } from './videoControls/videoControlsReducer';
import { batch } from 'react-redux';
import createSagaMiddleware, { EffectMiddleware } from 'redux-saga';
import { rootPlayerSaga } from './rootSaga';
import { getType } from 'typesafe-actions';
import { nextVideoApi } from './videoState/actions';
import { funnelStateReducer } from './funnelState/funnelStateReducer';

const environment = nodeEnv === 'production' ? 'prod' : 'dev';

const rememberedDismissedItemIdsConfig = {
  key: `voomly_rememberedDismissedItemIdsConfig_${environment}`,
  storage,
  whitelist: ['rememberedDismissedItemIds'],
};

const rememberedDisabledConfig = {
  key: 'key_doesnt_exist',
  storage,
  whitelist: ['key_doesnt_exist'],
};

const reducer = (isRememberDismissEnabled: boolean) =>
  combineReducers({
    // play, pause, volume, current progress, etc
    videoState: videoStateReducer,

    // allows user to jump on pre-defined timestamps. Maybe should be a part of videoControls?...
    chapters: chaptersReducer,

    // This is going to be extracted into the timeline items soon.
    ctaFooter: ctaFooterReducer,

    // width / height of the video
    dimensions: containerDimensionsReducer,

    // All about the video thumbnail (that's what we show before starting the video, like a placeholder)
    thumbnail: thumbnailReducer,

    // the play / pause icon which spawns with animation in the center of the video
    // in the even of playing / pausing
    playPauseOverlay: playPauseOverlayReducer,

    // if user watched a part of the video and closed the browser, we ask whether user wants
    // to continue playing right where they left off
    askToResumeOnVisitOverlay: askToResumeOnVisitOverlayReducer,

    // timeline items - buttons, texts, turnstiles, sharegates etc
    videoItemsOnScreen: persistReducer(
      isRememberDismissEnabled
        ? rememberedDismissedItemIdsConfig
        : rememberedDisabledConfig,
      videoItemsReducer
    ),

    // share state across the funnel (muted, speed, volume so on)
    funnelState: funnelStateReducer,

    // e.g. dimmed background, alternative duration bar (for grid), backButton
    miscScreenObjects: miscScreenObjectsReducer,

    // has info about the file, the player, etc.
    // do not overuse sourceConfiguration! Mostly only for timeline items which need to
    // know something about what they are playing
    sourceConfiguration: sourceConfigurationReducer,

    // Show/hide controls/logos/etc...
    videoControls: videoControlsReducer,
  });

export type IPlayerRootReducerShape = ReturnType<ReturnType<typeof reducer>>;

const effectMiddleware: EffectMiddleware = (next) => (effect) => {
  if (effect && effect.type === 'ALL') {
    batch(() => next(effect));
    return;
  }

  next(effect);
};

export const configurePlayerStore = (isRememberDismissEnabled: boolean) => {
  const composeEnhancers =
    // @ts-ignore
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          latency: 0,
          trace: true,
          traceLimit: 25,
          name: 'Player Store',
          actionsDenylist: ['videoState/TIME_UPDATED'],
          actionSanitizer: (action: AnyAction) => {
            if ('type' in action && action.type === getType(nextVideoApi)) {
              return {
                ...action,
                payload: { ...action.payload, api: '<VIDEO_API>' },
              };
            }

            return action;
          },
        })
      : compose;

  const sagaMiddleware = createSagaMiddleware({
    effectMiddlewares: [effectMiddleware],
  });

  const store = createStore(
    reducer(isRememberDismissEnabled),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  const task = sagaMiddleware.run(rootPlayerSaga);

  const persistor = persistStore(store);

  return {
    store,
    persistor,
    dispose: () => {
      task.cancel();
    },
  };
};
