import { createStandardAction } from 'typesafe-actions';
import { ITimelineItemClickDescriptor } from '../../types';

export const dismissVideoItemOnScreen = createStandardAction(
  'videoItems/DISMISS_ITEM'
)<{ id: string; remember: boolean }>();

export const highlightItemOnScreen = createStandardAction(
  'videoItems/HIGHLIGHT'
)<string | undefined>();

export const itemClicked = createStandardAction('videoItems/ITEM_CLICKED')<{
  item: ITimelineItemClickDescriptor;
}>();

export const resetHighlights = createStandardAction(
  'videoItems/RESET_HIGHLIGHTS'
)();
