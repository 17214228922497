import {
  IHasActionOnClick,
  IHasActionOnEnter,
  IHasActionOnPreExit,
} from '../types';

export const hasActionOnClick = (item: any): item is IHasActionOnClick => {
  const casted = item as IHasActionOnClick;
  return Boolean(casted.actionOnClick);
};

export const hasActionOnEnter = (item: any): item is IHasActionOnEnter => {
  const casted = item as IHasActionOnEnter;
  return Boolean(casted.actionsOnEnter);
};

export const hasActionOnPreExit = (item: any): item is IHasActionOnPreExit => {
  const casted = item as IHasActionOnPreExit;
  return Boolean(casted.actionsOnPreExit);
};
