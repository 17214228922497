import { ActionType, getType } from 'typesafe-actions';
import { ISourceConfiguration } from '../../PlayerContainer';
import * as actions from './actions';
import * as videoStateActions from '../videoState/actions';

export type ISourceConfigurationShape = {
  current?: ISourceConfiguration;
  next?: ISourceConfiguration;
  nextCurrentTime?: number;
};

const initialState: ISourceConfigurationShape = {};

type IAction = ActionType<typeof actions | typeof videoStateActions>;
const reducer = (
  state: ISourceConfigurationShape = initialState,
  action: IAction
): ISourceConfigurationShape => {
  switch (action.type) {
    case getType(actions.setSourceConfiguration):
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload,
        },
      };

    case getType(actions.preloadNextVideo):
      if (!state.current) return state;

      return {
        ...state,

        next: action.payload,
      };

    case getType(actions.promoteNextVideoToCurrent):
      if (!state.current || !state.next) return state;

      return {
        ...state,
        current: state.next,
        next: undefined,
        nextCurrentTime: state.next.playbackSettings.currentTime,
      };

    case getType(videoStateActions.updateTime):
      return {
        ...state,
        nextCurrentTime: undefined,
      };
  }

  return state;
};

export default reducer;
