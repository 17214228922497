import React, { Suspense, useCallback, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IPlayerWithVideo } from './types/player';
import { IPlayerVideo } from './types/video';
import Thumbnail from './components/Thumbnail/Thumbnail';
import {
  IDefaultPropTypes,
  PlayerMode,
} from './components/types/defaultPropTypes';
import styles from './components/Player/player.module.sass';
import { VideoItemsContainer } from './components/VideoItems/VideoItemsContainer';
import {
  selectButtonsScale,
  selectVideoHeight,
  selectVideoWidth,
} from './store/containerDimensions/selectors';
import {
  ITimelineItemClickDescriptor,
  ITimelineItemDismissDescriptor,
} from './types';
import DimmedBackground from './components/DimmedBackground';
import { Chapters } from './components/Chapters/Chapters';
import { AlternativeDurationLine } from './components/Controls/AlternativeDurationLine/AlternativeDurationLine';
import { IGeneralVideoControlInterface } from './components/Video/videoControlInterface';
import { DetachedStoreContext } from './contexts/DetachedStoreContext';
import { Video } from './components/Video/Video';
import { nextVideoApi } from './store/videoState/actions';
import { getChangingVideo } from './store/sourceConfiguration/selectors';
import {
  getIsVideoFinished,
  getShouldVideoBeVisible,
} from './store/videoState/selectors';
import { WelcomeBackLazyWrapper } from './components/WelcomeBack/WelcomeBackLazyWrapper';
import { Loader } from './components/Loader/index';

// Lazy components
const LazyError = React.lazy(() => import('./components/Error/Error'));

interface IProps {
  player: IPlayerWithVideo;
  file: IPlayerVideo;
  playerMode: PlayerMode;

  onTimelineItemClick?: (item: ITimelineItemClickDescriptor) => void;
  onTimelineItemDismiss?: (
    item: ITimelineItemDismissDescriptor,
    remember?: boolean
  ) => void;

  allControlsAreAlwaysVisible: boolean;
}

const Wrapper = styled.div<{ blurry: boolean; isBehind: boolean }>`
  ${({ blurry }) => css`
    transition: filter 0.4s ease-out;
    filter: blur(${blurry ? 10 : 0}px);
  `};
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${({ isBehind }) => (isBehind ? '1' : '2')};
  width: 100%;
  height: 100%;
`;

interface IQueueEntry {
  streamingUrl?: string;
}

const getQueueEntry = (file: IPlayerVideo): IQueueEntry => ({
  streamingUrl: file.url,
});

const StyledLoader = styled(Loader)`
  z-index: 50;
  transform: translateY(calc(-100% - 10px));
`;

const VideoWithControls = ({
  player,
  file,
  playerMode,
  onTimelineItemClick,
  onTimelineItemDismiss,
  allControlsAreAlwaysVisible,
}: IProps) => {
  const dispatch = useDispatch();
  const videoToPreload = useSelector(getChangingVideo);
  const currentQueueItem = getQueueEntry(file);
  const queue = [currentQueueItem];
  const [isShowingLoader, setIsShowingLoader] = useState(false);

  if (videoToPreload) {
    const queueEntry = getQueueEntry(videoToPreload.file);
    if (queueEntry.streamingUrl) {
      queue.push(queueEntry);
    }
  }

  const registerVideoApi = useCallback(
    (api: IGeneralVideoControlInterface) => {
      dispatch(nextVideoApi({ api }));
    },
    [dispatch]
  );

  const [errorType, setErrorType] = useState<
    | 'PLAYER_NOT_FOUND'
    | 'VIDEO_SOURCE_ERROR'
    | 'INCORRECT_FILE_PASSWORD'
    | undefined
  >(undefined);

  const width = useSelector(selectVideoWidth);
  const height = useSelector(selectVideoHeight);
  const buttonsScale = useSelector(selectButtonsScale);
  const isVideoFinished = useSelector(getIsVideoFinished);
  const shouldVideoBeVisible = useSelector(getShouldVideoBeVisible);

  const triggerVideoSourceError = (message: string) => {
    console.log('Video Source Error', message);
    setErrorType('VIDEO_SOURCE_ERROR');
  };

  const handleToggleLoader = useCallback((isBuffering: boolean) => {
    setIsShowingLoader(isBuffering);
  }, []);

  // FileUrl is undefined
  if (errorType || !currentQueueItem.streamingUrl)
    return (
      <Suspense fallback={null}>
        <LazyError
          errorType={errorType}
          videoConvertProgress={file.videoConvertProgress}
        />
      </Suspense>
    );

  const componentProps: IDefaultPropTypes = {
    playerMode,
    file,
    player,
    buttonsScale,
    allControlsAreAlwaysVisible,
  };

  return (
    <div className={cx(styles.videoRoot)}>
      <div className={styles.videoAndElements}>
        {/* This prevents screen flickering when first frame of the video interferes with the thumbnail animation */}
        <div
          className={styles.videoWrapper}
          style={{
            opacity:
              playerMode !== PlayerMode.THUMBNAIL_EDITOR && shouldVideoBeVisible
                ? 1
                : 0,
            width,
            height,
          }}
        >
          {queue.map((item, index) => (
            <Wrapper
              key={item.streamingUrl}
              blurry={queue.length > 1}
              isBehind={index > 0}
              data-marker="video-wrapper"
            >
              <Video
                streamingUrl={item.streamingUrl!}
                player={player}
                registerVideoApiToPlaybackManager={registerVideoApi}
                onVideoSourceError={triggerVideoSourceError}
                onVideoBufferingToggle={handleToggleLoader}
              />
              <StyledLoader
                isVisible={isShowingLoader}
                color={
                  player.appearance.bgColorEnabled
                    ? player.appearance.bgColor
                    : undefined
                }
              />
            </Wrapper>
          ))}
        </div>
        {(playerMode !== PlayerMode.MINI_PLAYER || isVideoFinished) && (
          <Thumbnail {...componentProps} />
        )}
        <DimmedBackground {...componentProps} />
        <Chapters {...componentProps} />
        <DetachedStoreContext.Consumer>
          {(contextProps) => {
            if (!contextProps) {
              return null;
            }

            return (
              <VideoItemsContainer
                onTimelineItemClick={onTimelineItemClick}
                onTimelineItemDismiss={onTimelineItemDismiss}
                buttonsScale={buttonsScale}
                playerMode={playerMode}
                itemWrapperHtmlElsRef={contextProps.itemWrapperHtmlElsRef}
              />
            );
          }}
        </DetachedStoreContext.Consumer>
        <WelcomeBackLazyWrapper {...componentProps} />
        <AlternativeDurationLine {...componentProps} />
      </div>
    </div>
  );
};

export default VideoWithControls;
