import * as React from 'react';
import {
  BaseTurnstileItem,
  IBaseTurnstileFormValues,
  ITurnstileItemProps,
} from './BaseTurnstile';
import { useSelector } from 'react-redux';
import { IBody, subscribe } from '../../../api/turnstile';
import { useCallback } from 'react';
import { IPlayerRootReducerShape } from '../../../store/rootPlayerStore';

export const PlayerTurnstileItem = (props: ITurnstileItemProps) => {
  const { item, onDismiss } = props;

  const file = useSelector(
    (state: IPlayerRootReducerShape) => state.sourceConfiguration.current?.file
  );
  const funnelId = useSelector(
    (state: IPlayerRootReducerShape) =>
      state.sourceConfiguration?.current?.funnelId
  );

  const handleSkip = useCallback(() => {
    onDismiss(item);
  }, [item, onDismiss]);

  const handleSubmit = useCallback(
    async (values: IBaseTurnstileFormValues) => {
      if (!funnelId && !file) {
        alert(item.failedToSubmitText);
        return false;
      }

      const valuesToSend: IBody = item.isNameRequired
        ? {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          }
        : { email: values.email };

      let result = {
        ok: false,
      };
      if (funnelId) {
        result = await subscribe(item.id, valuesToSend, { funnelId });
      } else if (file) {
        result = await subscribe(item.id, valuesToSend, {
          videoId: file.id,
          type: 'voomly',
        });
      }

      if (!result.ok) {
        alert(item.failedToSubmitText);
        return false;
      }

      onDismiss(item, true);
      return true;
    },
    [file, funnelId, item, onDismiss]
  );

  return (
    <BaseTurnstileItem
      {...props}
      onSubmit={handleSubmit}
      onSkip={handleSkip}
      isPreviewMode={false}
      titleText={item.titleText}
      subTitleText={item.subTitleText}
      formFirstNameText={item.formFirstNameText || 'First name'}
      formLastNameText={item.formLastNameText || 'Last name'}
      formEmailText={item.formEmailText || 'Email address'}
      formEmailTextRaw={item.formEmailText || 'Email address'}
    />
  );
};
