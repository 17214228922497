import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { requestQualityChange } from '../../../store/videoState/actions';
import styles from './styles.module.sass';
import { getQuality } from '../../../store/videoState/selectors';
import {
  addControlsStayVisibleBy,
  removeControlsStayVisibleBy,
} from '../../../store/videoControls/actions';
import { Select } from '../Select/Select';
import { usePlayerSkin } from '../skins/PlayerSkinContext';

type ISelectQualityProps = IDefaultPropTypes;

const SelectQualityComponent = ({
  player,
  file,
  player: { captions },
}: ISelectQualityProps) => {
  const dispatch = useDispatch();
  const quality = useSelector(getQuality);
  const skin = usePlayerSkin();

  const handleChangeOpen = useCallback(
    (opened: boolean) => {
      if (opened) {
        dispatch(addControlsStayVisibleBy('SELECT_QUALITY'));
      } else {
        dispatch(removeControlsStayVisibleBy('SELECT_QUALITY'));
      }
    },
    [dispatch]
  );

  const qualityOptions = useMemo(() => {
    return file.qualityOptions
      .map((option, idx) => ({
        label: option.label,
        value: idx,
      }))
      .concat([
        {
          label: 'Auto',
          value: -1,
        },
      ]);
  }, [file.qualityOptions]);

  const handleSelect = useCallback(
    (quality: number) => {
      dispatch(
        requestQualityChange({
          quality,
          hlsUrl:
            quality === -1
              ? file.url || ''
              : file.qualityOptions[quality].hlsUrl,
        })
      );
    },
    [dispatch, file.qualityOptions, file.url]
  );

  const buttonProps = useMemo(() => {
    const QualityIcon = skin.icons.quality;
    return {
      config: player,
      icon: <QualityIcon />,
      title: captions.qualityText,
    };
  }, [captions.qualityText, player, skin]);

  if (!player.controls.showSelectQualityButton || qualityOptions.length <= 2) {
    return null;
  }

  return (
    <div className={styles.selectQualityWrap}>
      <Select
        className={cx(
          skin.classes.selectQuality__root,
          styles.selectQualityDropdown
        )}
        buttonProps={buttonProps}
        config={player}
        options={qualityOptions}
        selectedValue={quality}
        onSelect={handleSelect}
        onChangeOpen={handleChangeOpen}
      />
    </div>
  );
};

export const SelectQuality = memo(SelectQualityComponent);
