import { cloneDeep, difference, pick, omit } from 'lodash-es';
import { DeepPartial } from 'ts-essentials';
import { IFontStyle } from '@voomly/ui/player-deps';
import { assertUnreachable } from '@voomly/utils';
import {
  IActionOnEnter,
  IActionOnPreExit,
  IFunnelItemClickAction,
  IThumbnailItem,
  ITimelineAnnotation,
  ITimelineButton,
  ITimelineEmail,
  ITimelineGrid,
  ITimelineGridButton,
  ITimelineHotspot,
  ITimelineHTML,
  ITimelineImage,
  ITimelineImageAnnotation,
  ITimelineItem,
  ITimelineItemBase,
  ITimelineShareGate,
  ITimelineText,
  ITimelineTurnstile,
} from '../types';
import {
  defaultTimelineAnnotation,
  defaultTimelineButton,
  defaultTimelineGrid,
  defaultTimelineImage,
  defaultTimelineImageAnnotation,
  defaultTimelineShareGate,
  defaultTimelineText,
  defaultTimelineTurnstile,
  WithoutBaseProps,
} from '../defaults/defaultTimelineItems';
import { IVideoChapter } from '../types/player';

const autofixFontStyle = (
  fontStyle: DeepPartial<IFontStyle[]> | undefined
): IFontStyle[] => {
  if (!fontStyle) return [];

  return fontStyle.filter((x) => Boolean(x)) as IFontStyle[];
};

const autofixGridButtons = (
  buttons: DeepPartial<ITimelineGridButton[]> | undefined
): ITimelineGridButton[] => {
  if (!buttons) return [];

  return buttons
    .filter((x) => Boolean(x))
    .map((x) => {
      const button = x!;
      return {
        actionOnClick: autofixActionsOnClick(button.actionOnClick),
        label: button.label ?? 'Button',
        // most likely if this is not defined, then the action on click is not defined to ->
        // button is just a visual thing w/o interactivity -> id as random string will just do
        id: button.id ?? Math.floor(Math.random() * 0xffffff).toString(16),
      };
    });
};

const autofixShared = (
  timelineItem: DeepPartial<ITimelineItemBase>
): Omit<ITimelineItemBase, 'type'> => {
  return {
    id: timelineItem.id!,
    label: timelineItem.label ?? 'item',
    startSecond: timelineItem.startSecond ?? 0,
    endSecond: timelineItem.endSecond ?? 1,
    zIndex: timelineItem.zIndex ?? 1,
  };
};

const autofixActionsOnEnter = (
  actionsOnEnter: DeepPartial<IActionOnEnter[]> | undefined
): IActionOnEnter[] => {
  if (!actionsOnEnter || actionsOnEnter.length === 0) {
    return [{ type: 'doNothing' }];
  }

  return actionsOnEnter.filter((x) => Boolean(x)) as IActionOnEnter[];
};

const autofixActionsOnPreExit = (
  actionsOnPreExit: DeepPartial<IActionOnPreExit[]> | undefined
): IActionOnPreExit[] => {
  if (!actionsOnPreExit || actionsOnPreExit.length === 0) {
    return [{ type: 'doNothing' }];
  }

  return actionsOnPreExit.filter((x) => Boolean(x)) as IActionOnPreExit[];
};

const autofixActionsOnClick = (
  actionOnClick: DeepPartial<IFunnelItemClickAction> | undefined
): IFunnelItemClickAction => {
  if (!actionOnClick) {
    return { type: 'doNothing' };
  }

  return actionOnClick as IFunnelItemClickAction;
};

const copyObjectOr = <T extends ITimelineItemBase>(
  original: DeepPartial<T>,
  myDefault: WithoutBaseProps<T>
): T => {
  const result = cloneDeep(myDefault);

  const defaultKeys = Object.keys(result);
  const originalKeys = Object.keys(original);
  const missingKeysInDefault = difference(originalKeys, defaultKeys);

  const extraData = pick(original, missingKeysInDefault);
  defaultKeys.forEach((key) => {
    // @ts-ignore
    if (typeof original[key] !== 'undefined') {
      // @ts-ignore
      result[key] = original[key];
    }
  });

  const withoutExtraData = {
    ...result,
    ...autofixShared(original),
  } as T;

  return {
    ...withoutExtraData,
    ...extraData,
  };
};

const autofixGrid = (
  timelineItem: DeepPartial<ITimelineGrid>
): ITimelineGrid => {
  const buttons = autofixGridButtons(timelineItem.buttons);
  return {
    ...copyObjectOr<ITimelineGrid>(timelineItem, defaultTimelineGrid),
    type: 'grid',
    actionsOnEnter: autofixActionsOnEnter(timelineItem.actionsOnEnter),
    actionsOnPreExit: autofixActionsOnPreExit(timelineItem.actionsOnPreExit),
    buttons,
    buttonsCount: timelineItem.buttonsCount ?? buttons.length,
    fontStyle: autofixFontStyle(timelineItem.fontStyle),
  };
};

const autofixIntegrationConfig = (
  config: DeepPartial<ITimelineTurnstile>['integrationConfig'] | undefined
): ITimelineTurnstile['integrationConfig'] => {
  if (!config || !config.name) return undefined;
  return config as ITimelineTurnstile['integrationConfig'];
};

const autofixTurnstile = (
  timelineItem: DeepPartial<ITimelineTurnstile>
): ITimelineTurnstile => {
  const integrationConfig = autofixIntegrationConfig(
    timelineItem.integrationConfig
  );

  return {
    ...copyObjectOr<ITimelineTurnstile>(timelineItem, defaultTimelineTurnstile),
    titleFontStyle: autofixFontStyle(timelineItem.titleFontStyle),
    subTitleFontStyle: autofixFontStyle(timelineItem.subTitleFontStyle),
    ...(integrationConfig ? { integrationConfig } : {}),
    formFirstNameText: timelineItem.formFirstNameText || 'First name',
    formLastNameText: timelineItem.formLastNameText || 'Last name',
    formEmailText: timelineItem.formEmailText || 'Email address',
    emailRequiredText:
      timelineItem.emailRequiredText || 'Email address is a required field',
    emailValidationText:
      timelineItem.emailValidationText || 'Enter a valid email address',
    failedToSubmitText:
      timelineItem.failedToSubmitText ||
      'Something went wrong, cannot submit the turnstile. Please contact support',
    skipText: timelineItem.skipText || 'Skip',
  };
};

const autofixShareGate = (
  timelineItem: DeepPartial<ITimelineShareGate>
): ITimelineShareGate => {
  return {
    ...copyObjectOr<ITimelineShareGate>(timelineItem, defaultTimelineShareGate),
    titleFontStyle: autofixFontStyle(timelineItem.titleFontStyle),
    subTitleFontStyle: autofixFontStyle(timelineItem.subTitleFontStyle),
    doneSharingText: timelineItem.doneSharingText || 'Done Sharing?',
    continueText: timelineItem.continueText || 'Continue',
    backText: timelineItem.backText || 'Back',
    skipText: timelineItem.skipText || 'Skip',
  };
};

const autofixButton = (
  timelineItem: DeepPartial<ITimelineButton>
): ITimelineButton => {
  return {
    ...copyObjectOr<ITimelineButton>(timelineItem, defaultTimelineButton),
    fontStyle: autofixFontStyle(timelineItem.fontStyle),
    actionOnClick: autofixActionsOnClick(timelineItem.actionOnClick),
    actionsOnEnter: autofixActionsOnEnter(timelineItem.actionsOnEnter),
    actionsOnPreExit: autofixActionsOnPreExit(timelineItem.actionsOnPreExit),
  };
};

const autofixText = (
  timelineItem: DeepPartial<ITimelineText>
): ITimelineText => {
  return {
    ...copyObjectOr<ITimelineText>(timelineItem, defaultTimelineText),
    fontStyle: autofixFontStyle(timelineItem.fontStyle),
  };
};

const autofixAnnotation = (
  timelineItem: DeepPartial<ITimelineAnnotation>
): ITimelineAnnotation => {
  return {
    ...copyObjectOr<ITimelineAnnotation>(
      timelineItem,
      defaultTimelineAnnotation
    ),
    actionOnClick: autofixActionsOnClick(timelineItem.actionOnClick),
    fontStyle: autofixFontStyle(timelineItem.fontStyle),
  };
};

const autofixImage = (
  image: DeepPartial<ITimelineImage['image']> | undefined
): ITimelineImage['image'] => {
  if (!image) return { type: 'notSet' };

  return image as ITimelineImage['image'];
};

const autofixTimelineImage = (
  timelineItem: DeepPartial<ITimelineImage>
): ITimelineImage => {
  return {
    ...copyObjectOr<ITimelineImage>(timelineItem, defaultTimelineImage),
    actionOnClick: autofixActionsOnClick(timelineItem.actionOnClick),
    actionsOnEnter: autofixActionsOnEnter(timelineItem.actionsOnEnter),
    actionsOnPreExit: autofixActionsOnPreExit(timelineItem.actionsOnPreExit),
    image: autofixImage(timelineItem.image),
  };
};

const autofixImageAnnotation = (
  timelineItem: DeepPartial<ITimelineImageAnnotation>
): ITimelineImageAnnotation => {
  return {
    ...copyObjectOr<ITimelineImageAnnotation>(
      timelineItem,
      defaultTimelineImageAnnotation
    ),
    actionOnClick: autofixActionsOnClick(timelineItem.actionOnClick),
    image: autofixImage(timelineItem.image),
  };
};

// Unexisting components yet
const autofixEmail = (
  timelineItem: DeepPartial<ITimelineEmail>
): ITimelineEmail => {
  return timelineItem as ITimelineEmail;
};

const autofixHotspot = (
  timelineItem: DeepPartial<ITimelineHotspot>
): ITimelineHotspot => {
  return timelineItem as ITimelineHotspot;
};

const autofixHTML = (
  timelineItem: DeepPartial<ITimelineHTML>
): ITimelineHTML => {
  return timelineItem as ITimelineHTML;
};

const autofixXY = (timelineItem: DeepPartial<ITimelineItem>): ITimelineItem => {
  if (
    timelineItem.type === 'grid' ||
    timelineItem.type === 'annotation' ||
    timelineItem.type === 'imageAnnotation' ||
    timelineItem.type === 'turnstile' ||
    timelineItem.type === 'shareGate'
  ) {
    return omit(timelineItem, ['x', 'y']) as ITimelineItem;
  }

  return timelineItem as ITimelineItem;
};

export const autofixTimelineItems = (
  timelineItems: DeepPartial<ITimelineItem[]> | undefined
): ITimelineItem[] => {
  if (!timelineItems || !timelineItems.length) {
    return [];
  }

  return timelineItems
    .filter((x) => Boolean(x) && x!.type)
    .map((x) => {
      const timelineItem = autofixXY(x!);

      switch (timelineItem.type) {
        case 'grid': {
          return autofixGrid(timelineItem);
        }

        case 'turnstile': {
          return autofixTurnstile(timelineItem);
        }

        case 'shareGate': {
          return autofixShareGate(timelineItem);
        }

        case 'button': {
          return autofixButton(timelineItem);
        }

        case 'text': {
          return autofixText(timelineItem);
        }

        case 'annotation': {
          return autofixAnnotation(timelineItem);
        }

        case 'email': {
          return autofixEmail(timelineItem);
        }

        case 'hotspot': {
          return autofixHotspot(timelineItem);
        }

        case 'html': {
          return autofixHTML(timelineItem);
        }

        case 'image': {
          return autofixTimelineImage(timelineItem);
        }

        case 'imageAnnotation': {
          return autofixImageAnnotation(timelineItem);
        }

        case undefined: {
          // should have filtered in the pre-filter section
          throw new Error('impossible');
        }
      }

      return assertUnreachable(timelineItem);
    });
};

export const autofixThumbnailItems = (
  thumbnailItems: DeepPartial<IThumbnailItem[]> | undefined
): IThumbnailItem[] => {
  if (!thumbnailItems) {
    return [];
  }

  return thumbnailItems
    .filter((x) => !!x && x?.type)
    .map((x) => {
      const thumbnailItem = autofixXY(x!) as IThumbnailItem;

      switch (thumbnailItem.type) {
        case 'button': {
          return autofixButton(thumbnailItem);
        }

        case 'text': {
          return autofixText(thumbnailItem);
        }

        case 'image': {
          return autofixTimelineImage(thumbnailItem);
        }

        case undefined: {
          // should have filtered in the pre-filter section
          throw new Error('impossible');
        }
      }

      return assertUnreachable(thumbnailItem);
    });
};

export const autofixPlayerChapters = (
  chapters: DeepPartial<IVideoChapter | undefined> | undefined
): IVideoChapter => {
  if (
    !chapters ||
    typeof chapters.isVisibleOnLoad === 'undefined' ||
    typeof chapters.enabled === 'undefined' ||
    typeof chapters.items === 'undefined'
  ) {
    chapters = {
      enabled: false,
      isVisibleOnLoad: false,
      items: [],
      label: 'Chapters',
    };
  }

  if (!chapters.label) {
    chapters = { ...chapters, label: 'Chapters' };
  }

  return chapters as IVideoChapter;
};
