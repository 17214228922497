import { Channel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';
import { eAnalyticsEventType } from '../../types/analytics';
import { getIsVideoPlaying } from '../videoState/selectors';
import { ISessionEventsWithConfig } from './eventsSender/eventsSenderSaga';
import { eInternalAnalyticEventType, IInternalAnalyticEvent } from './types';

type SeekingState = {
  startedAt: number;
};

const eventsMap = {
  [eInternalAnalyticEventType.play]: eAnalyticsEventType.play,
  [eInternalAnalyticEventType.pause]: eAnalyticsEventType.pause,
  [eInternalAnalyticEventType.finished]: eAnalyticsEventType.finish,
  [eInternalAnalyticEventType.playing]: eAnalyticsEventType.playing,
};

// It converts internal raw events to api events
export function* eventsConverterSaga(
  internalEventsCh: Channel<IInternalAnalyticEvent>,
  apiAnalyticsEventsCh: Channel<ISessionEventsWithConfig>
) {
  let currentConfigId: string | undefined;
  let currentSeekingState: SeekingState | undefined;

  let eventsI = 1;

  while (true) {
    const newEvent: IInternalAnalyticEvent = yield take(internalEventsCh);

    if (newEvent.config.id !== currentConfigId) {
      currentConfigId = newEvent.config.id;
      currentSeekingState = undefined;
    }

    if (newEvent.name === eInternalAnalyticEventType.seekStarted) {
      currentSeekingState = { startedAt: newEvent.second };
    } else if (
      newEvent.name === eInternalAnalyticEventType.seekFinished &&
      currentSeekingState
    ) {
      // We don't want to track too short seeks
      const isTiny =
        Math.abs(currentSeekingState?.startedAt - newEvent.second) < 1;
      if (!isTiny) {
        yield put(apiAnalyticsEventsCh, {
          ev: {
            name: eAnalyticsEventType.seek,
            fromSecond: Math.floor(currentSeekingState.startedAt),
            toSecond: Math.floor(newEvent.second),
            sequenceNumber: eventsI++,
            executedAt: new Date().toISOString(),
          },
          config: newEvent.config,
        });
      }

      // Let's also emit if player play/pause currently, cause it may happen during seek
      yield put(apiAnalyticsEventsCh, {
        ev: {
          name: getIsVideoPlaying(yield select())
            ? eAnalyticsEventType.play
            : eAnalyticsEventType.pause,
          sequenceNumber: eventsI++,
          second: Math.floor(
            isTiny ? currentSeekingState.startedAt : newEvent.second
          ),
          executedAt: new Date().toISOString(),
        },
        config: newEvent.config,
      });

      currentSeekingState = undefined;
    } else {
      // Skipping any event on seek, cause
      // html video api spam play/pause events during seek
      if (currentSeekingState) continue;

      if (!eventsMap[newEvent.name]) {
        console.error(
          `Don't know how to map event ${JSON.stringify(newEvent)}`
        );

        continue;
      }

      yield put(apiAnalyticsEventsCh, {
        ev: {
          name: eventsMap[newEvent.name],
          second: Math.floor(newEvent.second),
          sequenceNumber: eventsI++,
          executedAt: new Date().toISOString(),
        },
        config: newEvent.config,
      });
    }
  }
}
