import * as React from 'react';
import { IDetachableInnerProps } from '../detachableStoreHoc';
import { IVideoItemSharedProps } from '../sharedTypes';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../svg/cross-2.svg';
import {
  annotationMargin,
  StyledDismissButton,
} from '../Annotation/BaseAnnotation';
import { UploadableImageContent } from '../shared/UploadableImageContent';
import {
  ITimelineImageAnnotation,
  ITimelineItemClickDescriptor,
  ITimelineItemDismissDescriptor,
} from '../../../types';

interface IImageAnnotationProps {
  item: ITimelineImageAnnotation;
  buttonsScale: number;
  onDismiss?: (item: ITimelineItemDismissDescriptor) => void;
  onClick?: (item: ITimelineItemClickDescriptor) => void;
  className?: string;
  uploadProgress?: number | undefined;
}

export type IImageAnnotationItemProps =
  IDetachableInnerProps<ITimelineImageAnnotation> &
    IVideoItemSharedProps &
    IImageAnnotationProps;

interface IWrapperProps {
  item: ITimelineImageAnnotation;
  buttonsScale: number;
}

const Wrapper = styled.div.attrs<IWrapperProps>(({ item, buttonsScale }) => ({
  style: {
    zIndex: item.zIndex,
    width: item.width * buttonsScale,
    height: item.height * buttonsScale,
    margin: annotationMargin(buttonsScale),
  },
}))<IWrapperProps>`
  border: none;
  position: relative;
`;

const StyledCrossIcon = styled(CrossIcon)`
  color: #fff;
`;

export const ImageAnnotationItem = React.forwardRef(
  (
    {
      item,
      buttonsScale,
      onClick,
      onDismiss,
      className,
      uploadProgress,
    }: IImageAnnotationProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <Wrapper
      item={item}
      buttonsScale={buttonsScale}
      ref={ref}
      className={className}
    >
      {/** TS 4.1 fix typing - remove onClick - as any*/}
      <UploadableImageContent
        item={item}
        onClick={onClick as any}
        uploadProgress={uploadProgress}
        style={{ opacity: (item.opacity ?? 100) / 100 }}
      />

      {onDismiss && (
        <StyledDismissButton
          zIndex={item.zIndex}
          onClick={() => onDismiss(item)}
        >
          <StyledCrossIcon />
        </StyledDismissButton>
      )}
    </Wrapper>
  )
);
