import { Channel } from 'redux-saga';
import {
  select,
  race,
  take,
  delay,
  fork,
  put,
  cancelled,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { getConfigIds, IConfigIds } from '../sourceConfiguration/selectors';
import {
  videoSeeked,
  playEnded,
  videoSeekStarted,
  videoPaused,
  videoPlayStarted,
} from '../videoState/actions';
import { getCurrentTime, getIsVideoPlaying } from '../videoState/selectors';
import { eInternalAnalyticEventType, IInternalAnalyticEvent } from './types';
import { listenLatest } from '../utils';

function* trackSeek(ch: Channel<IInternalAnalyticEvent>, config: IConfigIds) {
  let currentSeek:
    | { seekFrom: number; seekTo: number; lastSeekAt: number }
    | undefined;

  // currentTime is needed to track the time before seek started
  // it also updates every 500ms

  // It maybe not so accurate, but we should react only on videoSeeked event,
  // cause in some cases videoSeekStarted may not be triggered
  let currentTime = getCurrentTime(yield select());

  try {
    while (true) {
      const {
        seek,
        playEnd,
      }: {
        seek: ReturnType<typeof videoSeeked> | undefined;
        newTime: number | undefined;
        playEnd: ReturnType<typeof playEnded> | undefined;
      } = yield race({
        seek: take([videoSeeked, videoSeekStarted]),
        timeout: delay(500),
        playEnd: take(playEnded),
      });

      // We finish seek if no more seek happened during 1 second
      if (
        currentSeek &&
        (new Date().getTime() - currentSeek.lastSeekAt > 1000 || playEnd)
      ) {
        yield put(ch, {
          name: eInternalAnalyticEventType.seekFinished,
          second: currentSeek.seekTo,
          config,
        });
        currentSeek = undefined;
      } else if (seek) {
        if (currentSeek) {
          currentSeek = {
            ...currentSeek,
            seekTo: seek.payload.time,
            lastSeekAt: new Date().getTime(),
          };
        } else {
          yield put(ch, {
            name: eInternalAnalyticEventType.seekStarted,
            second: currentTime,
            config,
          });
          currentSeek = {
            lastSeekAt: new Date().getTime(),
            seekFrom: seek.payload.time,
            seekTo: seek.payload.time,
          };
        }
      }

      if (playEnd) break;

      currentTime = getCurrentTime(yield select());
    }
  } finally {
    if (currentSeek && (yield cancelled())) {
      yield put(ch, {
        name: eInternalAnalyticEventType.seekFinished,
        second: currentSeek.seekTo,
        config,
      });
    }
  }
}

// Just need to be sure that user session is still active
// In each window of n(35s right now) seconds if video was playing we emit playing event
const WINDOW_TIME = 35_000;
function* trackPlaying(
  ch: Channel<IInternalAnalyticEvent>,
  config: IConfigIds
) {
  let wasPlayingInCurrentWindow = false;
  let currentWindowStartedAt = new Date().getTime();

  while (true) {
    if (new Date().getTime() - currentWindowStartedAt > WINDOW_TIME) {
      if (wasPlayingInCurrentWindow) {
        yield put(ch, {
          name: eInternalAnalyticEventType.playing,
          second: Math.ceil(getCurrentTime(yield select())),
          config,
        });
      }

      currentWindowStartedAt = new Date().getTime();
      wasPlayingInCurrentWindow = false;
    }

    const isPlaying = getIsVideoPlaying(yield select());

    if (isPlaying) wasPlayingInCurrentWindow = true;

    yield delay(1_000);
  }
}

const actionsToListen = [videoPaused, videoPlayStarted];
function* listenSimpleEvent(
  ch: Channel<IInternalAnalyticEvent>,
  config: IConfigIds
) {
  while (true) {
    const event: ReturnType<typeof actionsToListen[number]> = yield take(
      actionsToListen
    );

    const currentTime = getCurrentTime(yield select());

    switch (event.type) {
      case getType(videoPaused):
        yield put(ch, {
          name: eInternalAnalyticEventType.pause,
          second: Math.ceil(currentTime),
          config,
        });
        break;

      case getType(videoPlayStarted):
        yield put(ch, {
          name: eInternalAnalyticEventType.play,
          second: Math.ceil(currentTime),
          config,
        });
        break;

      default:
        console.error(`unrecognized type for event ${JSON.stringify(event)}!`);
    }
  }
}

function* trackFinish(
  ch: Channel<IInternalAnalyticEvent>,
  currentConfig: ReturnType<typeof getConfigIds>
) {
  // we need to know player time before playEnded triggered
  let currentTime = getCurrentTime(yield select());

  while (true) {
    const [onPlayEnd] = yield race([take(playEnded), take('*')]);

    if (onPlayEnd) {
      yield put(ch, {
        name: eInternalAnalyticEventType.finished,
        second: Math.ceil(currentTime),
        config: currentConfig,
      });

      break;
    }

    currentTime = getCurrentTime(yield select());
  }
}

function* startTracking(
  ch: Channel<IInternalAnalyticEvent>,
  currentConfig: ReturnType<typeof getConfigIds>
) {
  if (!currentConfig) {
    console.error("Can't track analytics events — config is undefined");

    return;
  }

  yield fork(trackSeek, ch, currentConfig);
  yield fork(trackPlaying, ch, currentConfig);
  yield fork(listenSimpleEvent, ch, currentConfig);
  yield fork(trackFinish, ch, currentConfig);
}

// On each new config emitted we stop previous events tracking and start new
export function* eventsTrackerSaga(ch: Channel<IInternalAnalyticEvent>) {
  yield listenLatest(getConfigIds, startTracking, ch);
}
