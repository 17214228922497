import { fork, put, select, take } from 'redux-saga/effects';
import { difference } from 'lodash-es';
import { getItemsOnScreen } from './selectors';
import { waitForChangeWithDiff } from '../utils';
import { ITimelineGrid, ITimelineItem } from '../../types';
import { requestPause } from '../videoState/actions';
import { hasActionOnEnter, hasActionOnPreExit } from '../../types/funnelGuards';
import { getCurrentTime } from '../videoState/selectors';
import { getPlayerMode } from '../sourceConfiguration/selectors';
import { toggleAlternativeDurationLine } from '../miscScreenObjects/actions';
import { PlayerMode } from '../../components/types/defaultPropTypes';

const isTimelineGrid = (item: ITimelineItem): item is ITimelineGrid => {
  return item.type === 'grid';
};

const getGridWithDurationLine = (items: ITimelineItem[]) => {
  const grid = items.find((x) => isTimelineGrid(x)) as
    | ITimelineGrid
    | undefined;

  if (
    grid &&
    grid.actionsOnEnter.find((x) => x.type === 'pause') &&
    grid.pauseAutoResumeIn > 0 &&
    !grid.isRequired
  ) {
    return grid;
  }

  return undefined;
};

function* handleEnter(items: ITimelineItem[]) {
  const newItemRequirePause = items.some((x) => {
    if (x.type === 'shareGate' || x.type === 'turnstile') return true;

    if (hasActionOnEnter(x)) {
      return x.actionsOnEnter.some((action) => action.type === 'pause');
    }

    return false;
  });

  if (newItemRequirePause) {
    // TODO: don't trigger overlay
    yield put(requestPause({}));
  }

  const grid = getGridWithDurationLine(items);
  if (getPlayerMode(yield select()) === PlayerMode.NORMAL && grid) {
    yield put(
      toggleAlternativeDurationLine({
        color: 0xf334c2,
        percents: 0,
        duration: grid.pauseAutoResumeIn,
        triggeredByItemId: grid.id,
        startAtTime: grid.startSecond,
      })
    );
  }
}

function* handleExit(items: ITimelineItem[]) {
  if (
    getPlayerMode(yield select()) === PlayerMode.NORMAL &&
    getGridWithDurationLine(items)
  ) {
    yield put(toggleAlternativeDurationLine(undefined));
  }
}

function* handlePreExit(items: ITimelineItem[]) {
  const requirePause = items.some((x) => {
    if (hasActionOnPreExit(x)) {
      return x.actionsOnPreExit.some((action) => action.type === 'pause');
    }

    return false;
  });

  if (requirePause) {
    // TODO: don't trigger overlay
    yield put(requestPause({}));
  }
}

function* exitAndEnterWatcher() {
  while (true) {
    yield take();

    const [prevItems, currentItems]: [
      ReturnType<typeof getItemsOnScreen>,
      ReturnType<typeof getItemsOnScreen>
    ] = yield waitForChangeWithDiff(getItemsOnScreen);

    const newItems = difference(currentItems, prevItems);
    const removedItems = difference(prevItems, currentItems);

    yield handleEnter(newItems);
    yield handleExit(removedItems);
  }
}

// Pre exit should happen before 1s of the item duration end
function* preExitWatcher() {
  let alreadyPreExitedItemIds: string[] = [];

  while (true) {
    yield take();
    const currentItems = getItemsOnScreen(yield select());
    const currentItemIds = currentItems.map(({ id }) => id);

    const currentTime = getCurrentTime(yield select());
    const itemsToPreExit = currentItems.filter(
      // eslint-disable-next-line no-loop-func
      (item) =>
        item.endSecond - 1 <= currentTime &&
        currentTime <= item.endSecond &&
        !alreadyPreExitedItemIds.includes(item.id)
    );

    yield handlePreExit(itemsToPreExit);
    alreadyPreExitedItemIds.push(...itemsToPreExit.map(({ id }) => id));

    // Remove those items that are not on screen already
    alreadyPreExitedItemIds = alreadyPreExitedItemIds.filter((id) =>
      currentItemIds.includes(id)
    );
  }
}

export function* itemLifecycleSaga() {
  yield fork(exitAndEnterWatcher);
  yield fork(preExitWatcher);
}
