import React from 'react';
import { memo, useMemo } from 'react';
import { ReactComponent as OkIcon } from '../../svg/ok.svg';
import { ReactComponent as ErrorIcon } from '../../svg/cross.svg';
import styled, { css, keyframes } from 'styled-components';
import { secondsToHms } from '@voomly/utils';

export type IProgressState =
  | {
      type: 'inProgress';
      value: number;
    }
  | {
      type: 'inTimeProgress';
      value: number;
      time: number;
    }
  | {
      type: 'done';
    }
  | {
      type: 'processing';
      value?: number;
    }
  | {
      type: 'inQueue';
    }
  | {
      type: 'failed';
    };

const getSize = ({ small, large }: { small: boolean; large: boolean }) => {
  if (small) return '24px';
  if (large) return '60px';
  return '40px';
};

const blink = keyframes`
  50% {
    color: transparent;
  }
`;
const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledAnimatedDiv = styled.div<{ animated?: boolean }>`
  ${({ animated }) =>
    animated &&
    css`
      animation: ${animation} 0.6s linear infinite;
    `}

  width: 100%;
  height: 100%;
`;

const StyledValue = styled.span<{
  white: boolean;
  large: boolean;
  small: boolean;
  failed: boolean;
  done: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  font-size: ${({ small, large }) => {
    if (small) return '7px';
    if (large) return '16px';
    return '10px';
  }};
  color: ${({ white }) => (white ? '#fff' : 'var(--gray-500)')};
  font-weight: 500;

  ${({ done, failed }) =>
    (done || failed) &&
    `
      opacity: 0;
      visibility: hidden;
  `}
`;

const StyledOk = styled.span<{
  done: boolean;
  small: boolean;
  large: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s ease-in;
  opacity: ${({ done }) => (done ? 1 : 0)};
  ${({ small }) =>
    small &&
    `
    svg {
      width: 12px;
      height: 12px;
    }
  `}
  ${({ large }) =>
    large &&
    `
    svg {
      width: 30px;
      height: 30px;
    }
  `}
`;

const StyledErrorIcon = styled.span<{
  failed: boolean;
  small: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s ease-in;
  opacity: ${({ failed }) => (failed ? 1 : 0)};

  svg {
    width: ${({ small }) => (small ? '12px' : '16px')};
    height: ${({ small }) => (small ? '12px' : '16px')};
  }
`;

const StyledPathTrack = styled.path`
  stroke: var(--blue-50);
  fill-opacity: 0;
`;

const StyledPathHead = styled.path<{
  white: boolean;
}>`
  transform-origin: center;
  transition: stroke-dashoffset 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  stroke: currentColor;
  stroke-linecap: round;
  fill-opacity: 0;
  color: ${({ white }) => white && '#1a99ff'};
`;

const StyledSVG = styled.svg<{
  large: boolean;
  small: boolean;
}>`
  display: block;
  width: ${getSize};
  height: ${getSize};
`;

const StyledRoot = styled.span<{
  white: boolean;
  large: boolean;
  small: boolean;
  failed: boolean;
  done: boolean;
}>`
  display: block;
  position: relative;
  color: ${({ white, done, failed }) => {
    if (white) return '#fff';
    if (done) return '#94D07C';
    if (failed) return '#F44336';
    return 'var(--primary-color, var(--azure-500))';
  }};
  width: ${getSize};
  height: ${getSize};
`;

export const Dot = styled.span<{ small?: boolean }>`
  display: inline-block;
  animation: 1s ${blink} infinite;
  font-size: ${({ small }) => (small ? '12px' : '20px')};
  font-weight: bold;
  line-height: 0px;

  &:nth-child(1) {
    animation-delay: 0ms;
  }

  &:nth-child(2) {
    animation-delay: 250ms;
  }

  &:nth-child(3) {
    animation-delay: 500ms;
  }
`;

const CircularProgressValue = ({
  state,
  small,
  large,
  white,
}: {
  state: IProgressState;
  small: boolean;
  large: boolean;
  white: boolean;
}) => {
  let content: React.ReactNode = null;
  switch (state.type) {
    case 'inProgress':
      content = `${Math.floor(state.value * 100)}%`;
      break;
    case 'inTimeProgress':
      content = secondsToHms(state.time);
      break;
    case 'processing':
      if (typeof state.value === 'undefined') {
        content = (
          <>
            <Dot small={small}>.</Dot>
            <Dot small={small}>.</Dot>
            <Dot small={small}>.</Dot>
          </>
        );
      } else {
        content = `${Math.floor(state.value! * 100)}%`;
      }

      break;
    case 'inQueue':
      content = 'Q';
      break;
    default:
      content = null;
  }

  return (
    <StyledValue
      failed={state.type === 'failed'}
      done={state.type === 'done'}
      white={white}
      large={large}
      small={small}
    >
      {content}
    </StyledValue>
  );
};

type ICircularProgressProps = {
  state: IProgressState;
  className?: string;
  small?: boolean;
  large?: boolean;
  white?: boolean;
  withoutDots?: boolean;
  withoutCircle?: boolean;
};

const CircularProgressComponent = ({
  className,
  state,
  small,
  large,
  white,
  withoutDots,
  withoutCircle,
}: ICircularProgressProps) => {
  const strokeDashoffset = useMemo(() => {
    if (state.type === 'inProgress' || state.type === 'inTimeProgress') {
      return 280 * (1 - (state.value || 0));
    }
    if (state.type === 'processing') return 210;
    return 0;
  }, [state]);

  return (
    <StyledRoot
      className={className}
      white={!!white}
      large={!!large}
      small={!!small}
      failed={state.type === 'failed'}
      done={state.type === 'done'}
    >
      {!withoutCircle && (
        <StyledAnimatedDiv animated={state.type === 'processing'}>
          <StyledSVG
            width="40"
            height="40"
            strokeWidth={6}
            viewBox="1.00 1.00 98.00 98.00"
            className={className}
            large={!!large}
            small={!!small}
          >
            <StyledPathTrack d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90" />
            <StyledPathHead
              d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
              pathLength="280"
              strokeDasharray="280 280"
              strokeDashoffset={strokeDashoffset}
              white={!!white}
            />
          </StyledSVG>
        </StyledAnimatedDiv>
      )}

      {!withoutDots && (
        <CircularProgressValue
          state={state}
          small={!!small}
          large={!!large}
          white={!!white}
        />
      )}

      <StyledOk large={!!large} small={!!small} done={state.type === 'done'}>
        <OkIcon />
      </StyledOk>

      <StyledErrorIcon small={!!small} failed={state.type === 'failed'}>
        <ErrorIcon />
      </StyledErrorIcon>
    </StyledRoot>
  );
};

export const CircularProgress = memo(CircularProgressComponent);

export const CircularProgressCentered = styled(CircularProgress)`
  position: absolute !important;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
`;
