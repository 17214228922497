import React from 'react';
import { IThumbnailItem, ITimelineItem } from '../types';

export interface IDetachedStoreContext {
  timelineItems: ITimelineItem[];
  thumbnailItems: IThumbnailItem[];
  detachedItemsById: Record<string, ITimelineItem>;

  detachItemFromStore: (itemId: string) => void;
  detachAllItemsFromStore: () => void;
  detachSelectedItemsFromStore: () => void;
  attachSelectedItemsToStore: () => void;
  attachItemToStore: (itemId: string) => void;
  attachAllItemsToStore: () => void;
  updateItem: (itemId: string, newData: Partial<ITimelineItem>) => void;
  getAllSelectedItemsValuesByField: (
    path: <T extends ITimelineItem>(item: T) => any,
    type: ITimelineItem['type']
  ) => any[];
  moveItem: (
    itemId: string,
    buttonsScale: number,
    newX: number,
    newY: number
  ) => void;
  updatePositionInTime: (
    itemId: string,
    startSecond: number,
    endSecond: number,
    draggingType: 'left' | 'right' | 'whole'
  ) => void;
  itemWrapperHtmlElsRef: React.RefObject<Record<string, HTMLElement>>;
  swapZIndexes: (itemId: string, draggingItemId: string) => void;

  selectedItemIds: string[];
  handleMultiSelect: (itemId: string, isModifierPressed: boolean) => void;
  handleMultiDeselect: () => void;
  deselectItem: (id: string) => void;
  deselectAll: () => void;

  handleRectangleSelectIntersection: (rect: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  }) => void;

  snappedToTimeSeconds: number | undefined;
  snappedToX: number | undefined;
  snappedToY: number | undefined;

  isThumbnailEditorActive: boolean;
}

export const DetachedStoreContext = React.createContext<
  IDetachedStoreContext | undefined
>(undefined);
