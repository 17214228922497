import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../svg/cross-2.svg';
import { IDetachableInnerProps } from '../detachableStoreHoc';
import { IVideoItemSharedProps } from '../sharedTypes';
import {
  VideoItemCommonText,
  IVideoItemTextProps,
} from '../shared/commonComponents';
import {
  ITimelineAnnotation,
  ITimelineItemClickDescriptor,
  ITimelineItemDismissDescriptor,
} from '../../../types';

export const annotationMargin = (buttonsScale: number) =>
  `0 0 ${15 * buttonsScale}px 0`;

const StyledCrossIcon = styled(CrossIcon)`
  color: #fff;
`;

export const StyledDismissButton = styled.button<{
  background?: string;
  zIndex: number;
}>`
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 18px;
  min-width: 18px;
  height: 18px;
  padding: 0;
  color: #728898;
  background: ${({ background }) => background || '#000'};
  outline: 0 !important;
  border: 0 !important;
  z-index: ${({ zIndex }) => zIndex || 10};
  svg {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover,
  &:focus {
    background: ${({ background }) => background || '#000'};
    color: #728898;
  }
`;

export const AnnotationWrapper = styled(
  React.forwardRef<HTMLDivElement, IVideoItemTextProps>((props, ref) => (
    <VideoItemCommonText
      width={200}
      margin={annotationMargin(props.buttonsScale)}
      {...props}
      ref={ref}
    />
  ))
)``;

interface IAnnotationProps {
  onDismiss?: (item: ITimelineItemDismissDescriptor) => void;
  onClick?: (item: ITimelineItemClickDescriptor) => void;
  className?: string;
}
export type IAnnotationItemProps = IDetachableInnerProps<ITimelineAnnotation> &
  IVideoItemSharedProps &
  IAnnotationProps;

export class BaseAnnotationItem extends React.PureComponent<
  IAnnotationItemProps,
  {}
> {
  private handleDismiss = () => {
    const { onDismiss, item } = this.props;

    if (onDismiss) {
      onDismiss(item);
    }
  };

  private handleOnClick = () => {
    const { onClick, item } = this.props;

    if (onClick) {
      onClick(item);
    }
  };

  public render() {
    const { item, buttonsScale, className } = this.props;

    return (
      <div>
        <AnnotationWrapper
          {...item}
          buttonsScale={buttonsScale}
          onClick={this.handleOnClick}
          className={className}
        >
          {item.label}
        </AnnotationWrapper>

        <StyledDismissButton
          type="button"
          background={item.backgroundColor}
          zIndex={item.zIndex}
          onClick={this.handleDismiss}
        >
          <StyledCrossIcon />
        </StyledDismissButton>
      </div>
    );
  }
}
