import axios from 'axios';

const validAuthUri = [
  'https://api.voomly-dev.com',
  'https://api.voomly-staging.com',
  'https://api.voomly.com',
  'http://localhost',
];

axios.interceptors.request.use(
  async (config) => {
    if (validAuthUri.every((uri) => config.url?.indexOf(uri) !== 0)) {
      return config;
    }

    config.headers = Object.assign(config.headers || {}, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Player-Version': '2',
      'Funnel-Version': '2',
    });
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
