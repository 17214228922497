import * as React from 'react';
import { IDetachableInnerProps } from '../detachableStoreHoc';
import { IVideoItemSharedProps } from '../sharedTypes';
import styled from 'styled-components';
import { chunk } from 'lodash-es';
import { IFontStyle, Flex } from '@voomly/ui/player-deps';
import { ITimelineGrid, ITimelineItemClickDescriptor } from '../../../types';
import tinycolor from '@ctrl/tinycolor';

const Wrapper = styled.div<{
  backgroundColor: string;
  opacity: number;
  buttonsScale: number;
}>(
  ({ backgroundColor, opacity }) => `
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  background: ${tinycolor(backgroundColor)
    .setAlpha(opacity / 100)
    .toRgbString()};
`
);

interface IButtonProps {
  buttonsScale: number;
  buttonColor: string;
  buttonOpacity: number;
  buttonRounded: number;
  fontColor: string;
  fontSize: number;
  hasActionOnButton: boolean;
}
export const Button = styled.div.attrs<IButtonProps>(
  ({
    buttonColor,
    fontSize,
    buttonsScale,
    buttonOpacity,
    fontColor,
    buttonRounded,
    hasActionOnButton,
  }): {
    style: React.CSSProperties;
  } => {
    return {
      style: {
        whiteSpace: 'normal',
        width: '100%',
        height: '100%',
        fontSize: fontSize * buttonsScale + 'px',
        color: fontColor,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        opacity: buttonOpacity / 100,
        borderRadius: buttonRounded * 0.15 * buttonsScale,
        padding: 15 * buttonsScale,
        cursor: hasActionOnButton ? 'pointer' : 'auto',
      },
    };
  }
)<IButtonProps>`
  overflow: hidden;
  display: flex;
  align-items: center;
  background: ${({ buttonColor }) => buttonColor};
  &:hover {
    background: ${({ buttonColor }) =>
      tinycolor(buttonColor).saturate(30).toHexString()};
  }
`;

const ButtonWrapper = styled.div<{ margin: number }>`
  flex: 1;
  margin: ${({ margin }) => `${margin}px`};
`;

interface ITitleProps {
  fontSize: number;
  fontStyle: IFontStyle[];
  color: string;
  buttonsScale: number;
}

const Title = styled.div.attrs<ITitleProps>(
  ({
    fontSize,
    fontStyle,
    color,
    buttonsScale,
  }): {
    style: React.CSSProperties;
  } => ({
    style: {
      fontWeight: fontStyle.includes('bold') ? 'bold' : undefined,
      fontStyle: fontStyle.includes('italic') ? 'italic' : undefined,
      fontSize: fontSize * buttonsScale + 'px',
      lineHeight: fontSize * 1.1 * buttonsScale + 'px',
      color: color,
    },
  })
)<ITitleProps>``;

const AnimationDiv = styled.div<{
  animationState: string;
  buttonsScale: number;
  appearanceAnimation: ITimelineGrid['appearanceAnimation'];
}>(({ buttonsScale, animationState, appearanceAnimation }) => {
  let transition = '';

  if (appearanceAnimation === 'slide-top-bottom') {
    const shouldBeVisible =
      animationState === 'entered' || animationState === 'exiting';
    transition = `
      transition-property: transform;
      transition-duration: 500ms;
      transform: ${shouldBeVisible ? 'translate(0, 0)' : 'translate(0, -100%)'}
    `;
  }

  return `
    display: flex;
    flex-direction: column;

    padding: ${20 * buttonsScale}px 0;
    padding-bottom: 40px;

    align-items: center;
    justify-content: center;

    ${transition}
  `;
});

export interface IBaseGridItemProps
  extends IDetachableInnerProps<ITimelineGrid>,
    IVideoItemSharedProps {
  onButtonClick: (item: ITimelineItemClickDescriptor) => void;
}

interface IPropsWithConfig extends IBaseGridItemProps {
  titleText: React.ReactNode;
  getLabelForButton: (index: number) => React.ReactNode;
}

export class BaseGridItem extends React.PureComponent<IPropsWithConfig> {
  private onButtonClick = (index: number) => {
    const {
      item,
      item: { buttons },
      onButtonClick,
    } = this.props;

    const clickedButton = buttons[index];
    onButtonClick({
      actionOnClick: clickedButton.actionOnClick,
      id: item.id + '|' + clickedButton.id,
      type: 'grid',
    });
  };

  public render() {
    const {
      item,
      buttonsScale,
      getLabelForButton,
      titleText,
      animationState,
      onWrapperMouseDown,
    } = this.props;

    return (
      <Wrapper
        onMouseDown={onWrapperMouseDown}
        opacity={item.backgroundOpacity}
        backgroundColor={item.backgroundColor}
        buttonsScale={buttonsScale}
      >
        <AnimationDiv
          animationState={animationState}
          buttonsScale={buttonsScale}
          appearanceAnimation={item.appearanceAnimation}
        >
          <Title
            color={item.fontColor}
            fontSize={item.fontSize}
            fontStyle={item.fontStyle}
            buttonsScale={buttonsScale}
          >
            {titleText}
          </Title>

          <Flex
            width="100%"
            height="100%"
            margin={`${20 * buttonsScale}px 0 0`}
            padding={`${(item.paddingButtonContainer / 4) * buttonsScale}px`}
            flexShrink={0}
          >
            {chunk(
              item.buttons.slice(0, item.buttonsCount),
              item.buttonsInRowCount
            ).map((chunk, chunkIndex) => (
              <Flex key={chunkIndex} container={true} justifyContent="center">
                {chunk.map((button, index) => {
                  const buttonIndex =
                    index + chunkIndex * item.buttonsInRowCount;
                  return (
                    <ButtonWrapper
                      key={index}
                      margin={(item.paddingBetweenButtons / 10) * buttonsScale}
                    >
                      <Button
                        buttonsScale={buttonsScale}
                        buttonColor={item.buttonColor}
                        fontColor={item.buttonTextColor}
                        buttonOpacity={item.buttonOpacity}
                        buttonRounded={item.buttonRounded}
                        fontSize={item.buttonFontSize}
                        hasActionOnButton={
                          button.actionOnClick.type !== 'doNothing'
                        }
                        onClick={() => this.onButtonClick(buttonIndex)}
                      >
                        {getLabelForButton(buttonIndex)}
                      </Button>
                    </ButtonWrapper>
                  );
                })}
              </Flex>
            ))}
          </Flex>
        </AnimationDiv>
      </Wrapper>
    );
  }
}
